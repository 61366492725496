import { useCallback, useContext, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import updateSlideSequences from '../../utils/updateSlideSequences';
import { multiSelectTo, reorderItems, swap } from './utils';

import SlideOverviewItem from './SlideOverviewItem';

const getItemStyle = (draggableStyle, virtualizedStyle) => {
  return {
    ...draggableStyle,
    ...virtualizedStyle,
    margin: 0,
    outline: 'none',
  };
};

/**
 * This component manages a copy of the slides.
 */
const SlideOverview = ({
  currentSlide,
  slides,
  hasViewModeSelector,
  selectedSlideIds,
  setCurrentSlide,
  setSelectedSlideIds,
  setSlideSequences,
  onInsertSlide,
  onRemoveSlides,
  onDuplicateSlide,
  disabled,
  canSwitchSlides,
  setShowCreateNewVersionDialog,
}) => {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);

  const onMoveUp = useCallback(
    (slide) => {
      const index = slides.findIndex((s) => s.id === slide.id);

      if (index === 0) {
        return;
      }

      const [slideSequences] = updateSlideSequences(
        swap(slides, index, index - 1),
      );

      setSlideSequences(slideSequences);
    },
    [slides, setSlideSequences],
  );

  const onMoveDown = useCallback(
    (slide) => {
      const index = slides.findIndex((s) => s.id === slide.id);

      if (index === slides.length - 1) {
        return;
      }

      const [slideSequences] = updateSlideSequences(
        swap(slides, index, index + 1),
      );
      setSlideSequences(slideSequences);
    },
    [slides, setSlideSequences],
  );

  const onInsert = useCallback(
    (slide) => {
      onInsertSlide(slide);
    },
    [onInsertSlide],
  );

  const onDelete = useCallback(
    (slide) => {
      if (selectedSlideIds.length === slides.length) {
        addNotification({
          type: 'warning',
          content: t('content-editor.errors.attempting_to_delete_all'),
        });

        return;
      }

      // Delete one slide that was not selected
      if (!selectedSlideIds.includes(slide.id)) {
        const result = slides.filter((s) => s.id !== slide.id);
        const [slideSequences] = updateSlideSequences(result);

        onRemoveSlides([slide], slideSequences);

        return;
      }

      // Delete all slides that were selected.
      const slidesToRemove = slides.filter((s) =>
        selectedSlideIds.includes(s.id),
      );

      const slidesToKeep = slides.filter(
        (s) => !selectedSlideIds.includes(s.id),
      );

      const [slideSequences] = updateSlideSequences(slidesToKeep);

      // Calculate closest slide to set as the new current slide
      const nextSlide =
        slidesToKeep.find(
          (s) => s.sequence === slidesToRemove[0].sequence - 1,
        ) || slidesToKeep[0];

      setCurrentSlide(nextSlide);
      onRemoveSlides(slidesToRemove, slideSequences);
      setSelectedSlideIds(nextSlide ? [nextSlide.id] : []);
    },
    [
      selectedSlideIds,
      slides,
      setCurrentSlide,
      onRemoveSlides,
      setSelectedSlideIds,
      addNotification,
      t,
    ],
  );

  const onDuplicate = useCallback(
    (slide) => {
      onDuplicateSlide(slide);
    },
    [onDuplicateSlide],
  );

  const onDragStart = (result) => {
    const slideId = result.draggableId;

    if (!selectedSlideIds.includes(slideId)) {
      setSelectedSlideIds([result.draggableId]);
    }

    setCurrentSlide(slides.find((o) => o.id === slideId));
  };

  const onDragEnd = (result) => {
    // dropped outside the Droppable or published
    if (disabled) {
      setShowCreateNewVersionDialog(true);
      return;
    }

    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const newlyOrderedSlides = reorderItems(slides, selectedSlideIds, result);

    const [slideSequences] = updateSlideSequences(newlyOrderedSlides);
    setSlideSequences(slideSequences);
  };

  const toggleSelection = useCallback(
    (slide) => {
      setSelectedSlideIds([slide.id]);
      setCurrentSlide(slide);
    },
    [setSelectedSlideIds, setCurrentSlide],
  );

  const toggleSelectionGroupKey = useCallback(
    (slide) => {
      if (!selectedSlideIds.includes(slide.id)) {
        setSelectedSlideIds((s) => [...s, slide.id]);
      } else {
        setSelectedSlideIds((s) => s.filter((id) => id !== slide.id));
      }
    },
    [selectedSlideIds, setSelectedSlideIds],
  );

  const toggleSelectionShiftKey = useCallback(
    (slide) => {
      setSelectedSlideIds(multiSelectTo(slide, slides, selectedSlideIds));
    },
    [slides, setSelectedSlideIds, selectedSlideIds],
  );

  const downHandler = useCallback(
    function handler({ key }) {
      if (canSwitchSlides) {
        const index = slides.findIndex((s) => s.id === currentSlide.id);

        if (key === 'ArrowDown' && index < slides.length - 1) {
          toggleSelection(slides[index + 1]);
        }

        if (key === 'ArrowUp' && index > 0) {
          toggleSelection(slides[index - 1]);
        }
      }
    },
    [currentSlide.id, canSwitchSlides, slides, toggleSelection],
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [currentSlide, downHandler, slides, selectedSlideIds]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="droppable-overview">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {slides.map((slide, index) => (
              <Draggable draggableId={slide.id} index={index} key={slide.id}>
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    className="w-full"
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={getItemStyle(
                      draggableProvided.draggableProps.style,
                      {},
                    )}
                  >
                    <div className="pb-2">
                      <div className="w-full">
                        <SlideOverviewItem
                          hasViewModeSelector={hasViewModeSelector}
                          disabled={disabled}
                          slideId={slide.id}
                          index={index}
                          isSelected={selectedSlideIds.includes(slide.id)}
                          draggableSnapshot={draggableSnapshot}
                          toggleSelection={toggleSelection}
                          toggsleSelectionGroupKey={toggleSelectionGroupKey}
                          toggleSelectionShiftKey={toggleSelectionShiftKey}
                          onInsert={() => onInsert(slide)}
                          onDelete={() => onDelete(slide)}
                          onMoveDown={() => onMoveDown(slide)}
                          onMoveUp={() => onMoveUp(slide)}
                          onDuplicate={() => onDuplicate(slide)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

SlideOverview.defaultProps = {
  slides: [],
};

export default SlideOverview;
