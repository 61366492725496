import { useLazyQuery } from '@apollo/client';

import { SEARCH_CONTENT_BY_NAME } from '../../../../api/filemanager';

const useFileSearch = () => {
  const [search, { data, loading, error }] = useLazyQuery(
    SEARCH_CONTENT_BY_NAME,
  );

  return { data: data?.searchContentByName, loading, error, search };
};

export default useFileSearch;
