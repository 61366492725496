import { useContext, useState } from 'react';
import {
  ArrowTopRightOnSquareIcon,
  ClipboardIcon,
  DocumentIcon,
  FolderIcon,
} from '@heroicons/react/24/solid';

import {
  FilledButton,
  FilledButtonLink,
  Input,
  NotificationContext,
} from '@ftrprf/tailwind-components';

import useContentRename from '../../../hooks/graphql/file-manager/mutations/useContentRename';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { fileDateFormatter } from '../utils/fileDateFormatter';
import { fileSizeFormatter } from '../utils/fileSizeFormatter';

import { FileManagerContext } from '../FileManagerContextProvider';

import FileUsagesDetail from './FileUsagesDetail';

let saveTimer;

const ContentPreview = ({ content }) => {
  const t = useFormatMessage();
  const {
    refetchCurrentFolderContent,
    isSearching,
    setIsSearching,
    setSelectedContent,
    setActiveFolder,
  } = useContext(FileManagerContext);
  const { addNotification } = useContext(NotificationContext);

  const [fileName, setFileName] = useState(content.name);

  const { renameContent } = useContentRename();

  const onNameChange = (e) => {
    const value = e.target.value;
    clearTimeout(saveTimer);
    saveTimer = setTimeout(async () => {
      // nameInputRef.current.blur();
      await renameContent({
        variables: { contentFileId: content.id, newName: value },
      });
      refetchCurrentFolderContent();
    }, 3000);

    setFileName(value);
  };

  const copyDownloadLink = () => {
    navigator.clipboard.writeText(content.blobUri);
    addNotification({
      type: 'success',
      content: t('global.copied'),
    });
  };

  return (
    <div className="flex-initial w-[30%] p-4">
      <h2 className="sr-only">{t('file.manager.file-detail')}</h2>
      <div className="flex justify-center relative">
        <div className="w-64 max-h-32 overflow-hidden grid place-items-center">
          {content.thumbnailLarge ? (
            <img alt="" src={content.thumbnailLarge} className="object-cover" />
          ) : content.isFile ? (
            <DocumentIcon className="w-32" />
          ) : (
            <FolderIcon className="w-32" />
          )}
        </div>
        {content.blobUri && (
          <FilledButtonLink
            className="absolute bottom-2 right-2 text-sm"
            href={content.blobUri}
            target="_blank"
            download
          >
            {t('global.download')}
          </FilledButtonLink>
        )}
      </div>
      <div className="mt-5 flex flex-col gap-2">
        <div className="relative">
          <Input
            value={fileName}
            onChange={onNameChange}
            disabled={isSearching}
          />
          {content.isFile && (
            <div className="absolute h-full top-0 right-0 border border-gray-300 rounded-r px-2 py-1 bg-white border-l ">
              .{content.extension}
            </div>
          )}
        </div>
        {content.blobUri && (
          <FilledButton
            className="justify-center"
            iconBefore={ClipboardIcon}
            onClick={copyDownloadLink}
          >
            {t('file.manager.copy-link')}
          </FilledButton>
        )}
        {isSearching && (
          <FilledButton
            className="justify-center"
            iconBefore={ArrowTopRightOnSquareIcon}
            onClick={() => {
              setActiveFolder({
                id: content.parentId,
                path: content.path.replace(`/${content.name}`, ''),
              });
              setIsSearching(false);
              setSelectedContent([]);
            }}
          >
            {t('file.manager.go-to-folder')}
          </FilledButton>
        )}
        <div>
          <ul>
            <li>
              {t('file.manager.creator')}: {content.createdBy}
            </li>
            <li>
              {t('file.manager.created-on')}:{' '}
              {fileDateFormatter(content.createdOn)}
            </li>
            <li>
              {t('file.manager.last-modified-by')}: {content.modifiedBy}
            </li>
            <li>
              {t('file.manager.last-modified-on')}:{' '}
              {fileDateFormatter(content.modifiedOn)}
            </li>
            {content.isFile && (
              <li>
                {t('file.manager.file-size')}:{' '}
                {fileSizeFormatter(content.fileSize)}
              </li>
            )}
          </ul>
        </div>
        {content.isFile && (
          <>
            <h3 className="font-bold">{t('file.manager.usage')}</h3>
            <FileUsagesDetail slides={content.slides} />
          </>
        )}
      </div>
    </div>
  );
};

export default ContentPreview;
