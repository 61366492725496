import { domains } from '../utils/constants/domains';

import { externalEn } from './external';
import { generate } from './generate';

export default {
  // Globals
  'global.add': 'Add',
  'global.edit': 'Edit',
  'global.copy': 'Copy',
  'global.copied': 'Copied',
  'global.cancel': 'Cancel',
  'global.close': 'Close',
  'global.delete': 'Delete',
  'global.confirm': 'Confirm',
  'global.save': 'Save',
  'global.saving': 'Saving...',
  'global.saved': 'Saved',
  'global.rename': 'Rename',
  'global.name': 'Name',
  'global.folder': 'Folder',
  'global.on': 'on',
  'global.empty': "Looks like there's nothing here!",
  'global.username': 'Username',
  'global.answer': 'Answer',
  'global.question': 'Question',
  'global.go_back': 'Go back',
  'global.no_results': 'No results found',
  'global.try-again': 'Try again',
  'global.not_required': 'Not required',
  'global.URL': 'URL',
  'global.archive': 'Archive',
  'global.unarchive': 'Unarchive',
  'global.view': 'View',
  'global.search': 'Search',
  'global.upload': 'Upload',
  'global.download': 'Download',
  'global.testplan': 'Testplan',
  'global.duplicate': 'Duplicate',
  'global.height': 'Height',
  'global.width': 'Width',

  // Languages
  'languages.nl': 'Nederlands',
  'languages.en': 'English',

  // Sign in page
  'login.username': 'Username',
  'login.password': 'Password',
  'login.login': 'Sign in',
  'login.title': 'Studio',
  'login.forgot_password': 'Forgot username/password?',
  [`login.welcome_message.${domains.CODEFEVER}`]: 'Welcome to the CodeFever Studio! The place to be for making lessons and exercises.',
  [`login.welcome_message.${domains.FTRPRF}`]: 'Welcome to the FTRPRF Studio! The place to be for making lessons and exercises.',
  [`login.switch_to.${domains.CODEFEVER}`]: 'I am a CodeFever user',
  [`login.switch_to.${domains.FTRPRF}`]: 'I am a FTRPRF user',
  'login.or': 'or',

  // Header
  'header.dashboard': 'Dashboard',
  'header.lessons': 'Lessons',
  'header.templates': 'Templates',
  'header.exams': 'Exams',
  'header.exercises': 'Exercises',
  'header.files': 'My files',
  'header.admin': 'Admin',
  'header.hello': 'Hello, ',
  'header.sign_out': 'Sign Out',
  'header.navmenu': 'Navigation menu',
  'header.usermenu': 'User menu',
  'header.close_menu': 'Close menu',
  'header.back_to_FTRPRF': 'Back to FTRPRF',

  // viewModes
  'view-modes.teacher_manual': 'Teacher manual',
  'view-modes.projection': 'Projection',
  'view-modes.selfstudy': 'Self study',
  'view-modes.classical': 'Classical',

  // contentStates
  'content-state.CONCEPT': 'Concept',
  'content-state.PUBLISHED': 'Published',
  'content-state.ARCHIVED': 'Archived',

  // content-overview
  'content-overview.toggle_filter': 'Filter',
  'content-overview.filters.title': 'Title',
  'content-overview.filters.programme': 'Program',
  'content-overview.filters.clan': 'Clan',
  'content-overview.filters.level': 'Level',
  'content-overview.filters.lesson': 'Lesson',
  'content-overview.filters.version': 'Version',
  'content-overview.filters.state': 'State',
  'content-overview.filters.tags': 'Tags',

  ...generate(
    [
      { key: 'EXAM', name: 'exam', plural: 'exams' },
      { key: 'LESSON', name: 'lesson', plural: 'lessons' },
      {
        key: 'LESSON_TEMPLATE',
        name: 'template',
        plural: 'templates'
      },
    ],
    {
      'content-overview.{{ key }}.title': '{{ name | ucase }} overview',
      'content-overview.{{ key }}.new_content': 'New {{ name }}',
      'content-overview.{{ key }}.empty': 'As of yet, no {{ plural }} can be found',
      'content-overview.{{ key }}.copy.success': 'The {{ name }} has been successfully copied',
      'content-overview.{{ key }}.new-content.success': 'A new {{ name }} has been successfully added',
      'content-overview.{{ key }}.publish.success': 'The {{ name }} has been successfully published',
      'content-overview.{{ key }}.archive.success': 'The {{ name }} is being archived. This may take a few seconds.',
      'content-overview.{{ key }}.unarchive.success': 'The {{ name }} is being unarchived. This may take a few seconds.',
      'content-overview.{{ key }}.remove.success': 'The {{ name }} has been successfully removed',
      'content-overview.{{ key }}.meta.success': 'The metadata of the {{ name }} is updated.',
      'content-meta-data-dialog.{{ key }}.new_title': 'New {{ name }}',
      'content-meta-data-dialog.{{ key }}.update_title': 'Edit {{ name }}',
    },
  ),

  'content-overview.new-version.success': 'A new version is successfully created.',
  'content-editor.warning.delete': 'A lesson should contain at least 1 slide',

  // content meta data dialog
  

  // Lesson Card
  'content-card.image_alt': 'Lesson image',

  // Lesson Content
  'content.title': 'Title',
  'content.type': 'Type',
  'content.language': 'Language',
  'content.tags': 'Tags',
  'content.endGoals': 'Goals',
  'content.filter':'Only letters and numbers are allowed',
  'content.image': 'Image',
  'content.clan': 'Clan',
  'content.level': 'Level',
  'content.version': 'Version',
  'content.program': 'Program',
  'content.last_modified_by': 'Last modified by',
  'content.last_modified_on': 'Last modified on',
  'content.groupRoles': 'Group Roles',
  'content.description': 'Description',
  'content.summary': 'Summary',
  'content.template': 'Choose template',
  'content.template.view': 'view template',
  'content.group_lesson': 'Group Lesson',

  // Slide overview
  'slide-thumbnail.contains-question': 'Slide contains question',

  // Studio
  'content-editor.lesson_title.placeholder': 'Lesson title...',
  'content-editor.title.placeholder': 'Slide title...',
  'content-editor.add_slide': 'Slide',
  'content-editor.add_activity': 'Activity',
  'content-editor.return_to_editor':'Back to editor',
  'link-plugin.url.dialog': 'URL Dialog',
  'link-plugin.url.add-url': 'Add URL',
  'link-plugin.url.modify-url': 'Modify URL',
  'link-plugin.url.add-exercise': 'Add Exercise',
  'link-plugin.url.modify-exercise': 'Modify Exercise',
  'link-plugin.url.link-style.label': 'URL style',
  'link-plugin.url.warning': 'This slide and destination slide should have exactly the same viewmodes and be in the same lesson. Otherwise errors could occur.',
  'link-plugin.file_name': 'File name',
  'link-plugin.is_download': 'Is a download link',
  'link-plugin.open_new_tab': 'Open link in a new tab',
  'link-plugin.display-text': 'Display text',
  'link-plugin.link-style.ExerciseLink': 'Exercise',
  'link-plugin.link-style.OtherLink': 'Other',
  'link-plugin.link-style.SlideLink': 'Slide',
  'link-plugin.link-style.VideoLink': 'Video',
  'link-plugin.link-style.ExternalLink': 'External',
  'link-plugin.link-style.DownloadLink': 'Download',
  'link-plugin.link-style.SteamsQR': 'QR',
  'exercise-plugin.url.exercise-default': 'Open exercise',

  'content-editor.header.last_edit_was': 'Last edit was',
  'content-editor.header.preview': 'Preview',
  'content-editor.header.metadata': 'Metadata',
  'content-editor.header.actions': 'Actions',
  'content-editor.header.actions.archive': 'Archive',
  'content-editor.header.actions.save': 'Save',
  'content-editor.header.publish': 'Publish',
  'content-editor.header.new_version': 'New version',
  'content-editor.header.settings': 'Settings',
  'content-editor.header.copy': 'Copy',
  'content-editor.header.archive': 'Archive',
  'content-editor.new_version_confirm': 'Do you want to create a new version?',

  ...generate(
    [
      { key: 'EXAM', name: 'exam' },
      { key: 'LESSON', name: 'lesson' },
      { key: 'LESSON_TEMPLATE', name: 'template' },
    ],
    {
      'content-editor.{{ key }}.publish-confirm.title': 'Publish {{ name }}',
      'content-editor.{{ key }}.publish-confirm.text1': 'Are you sure you want to publish this {{ name }}?',
      'content-editor.{{ key }}.publish-confirm.text2': `You won't be able to change this lesson unless you create a new version.`,
    },
  ),

  'content-editor.errors.attempting_to_delete_all': "You're trying to delete all slides but a lesson must contain at least one slide.",

  'content-editor.context_menu.insert_slide': 'Insert slide',
  'content-editor.context_menu.move_up': 'Move up',
  'content-editor.context_menu.move_down': 'Move down',
  'content-editor.buttons.hints': 'Hints',
  'content-editor.buttons.add_hint': 'Add hint',
  'content-editor.buttons.exercise': 'Exercise',
  'content-editor.buttons.teacher_info': 'Teacher info',
  'content-editor.buttons.add_question': 'Add question',
  'content-editor.buttons.flow_diagram': 'Steams schema',
  'content-editor.slide.modify_question': 'Modify question',
  'content-editor.slide.modify-content': 'Modify content of slide',
  'content-editor.no_viewmodes.error': 'At least 1 view mode should be selected.',
  'content-editor.question.multiple-choice.add-answer': 'New answer',

  'content-editor.overview.projection_warning': 'Classical slides are always visible in the projection view',

  'content-editor.question.open-question.info': 'This is the place where students will fill in their answer of the open question.',
  'content-editor.change_question.confirm': 'The answers will be lost when you change the slide type.',

  'content-editor.styles.default': 'Default',
  'content-editor.styles.intertitle': 'Intertitle',
  'content-editor.styles.subtitle': 'Subtitle',
  'content-editor.styles.question': 'Question',
  'content-editor.styles.text': 'Text',
  'content-editor.styles.definition': 'Definition',
  'content-editor.styles.tip': 'Tip',
  'content-editor.styles.bron': 'Source',

  'content-editor.colors.scratch.beweging': 'Motion',
  'content-editor.colors.scratch.uiterlijken': 'Looks',
  'content-editor.colors.scratch.geluid': 'Sound',
  'content-editor.colors.scratch.gebeurtenis': 'Events',
  'content-editor.colors.scratch.besturen': 'Control',
  'content-editor.colors.scratch.waarnemen': 'Sensing',
  'content-editor.colors.scratch.functies': 'Operators',
  'content-editor.colors.scratch.variabelen': 'Variables',
  'content-editor.colors.scratch.mijn_blokken': 'My blocks',
  'content-editor.colors.scratch.pen_makey': 'Pen and MakeyMakey',

  'content-editor.colors.minecraft.basic': 'Basic',
  'content-editor.colors.minecraft.player': 'Player',
  'content-editor.colors.minecraft.blocks': 'Blocks',
  'content-editor.colors.minecraft.mobs': 'Mobs',
  'content-editor.colors.minecraft.agent': 'Agent',
  'content-editor.colors.minecraft.gameplay': 'Gameplay',
  'content-editor.colors.minecraft.positions': 'Postitions',
  'content-editor.colors.minecraft.loops': 'Loops',
  'content-editor.colors.minecraft.logic': 'Logic',
  'content-editor.colors.minecraft.variables': 'Variables',
  'content-editor.colors.minecraft.math': 'Math',
  'content-editor.colors.minecraft.functions': 'Functions',
  'content-editor.colors.minecraft.arrays': 'Arrays',
  'content-editor.colors.minecraft.text': 'Text',
  'content-editor.colors.minecraft.builder': 'Builder',
  'content-editor.colors.minecraft.shapes': 'Shapes',

  'content-editor.colors.white': 'White',
  'content-editor.colors.subtitle-defintion.color': 'Subtitle and definition',

  'content-editor.activity.title': 'Activity',
  'content-editor.activity.type':'Activity type',
  'content-editor.activity.type_placeholder':'Search or create activity',
  'content-editor.activity.image_add':'Add icon',
  'content-editor.activity.image_change':'Change icon',
  'content-editor.activity.method':'Method',
  'content-editor.activity.image.select':'Select image',


  // SlideType
  'slide_type.TEXT': 'Text slide',
  'slide_type.MULTIPLE_CHOICE': 'Multiple choice',
  'slide_type.OPEN': 'Open question',

   // SlideMethod
 'slide_method.CLASS': 'Class',
 'slide_method.INDIVIDUALLY': 'Individually',
 'slide_method.GROUP': 'Group',

  // SlidePart
  'slide_part.INTRODUCTION': 'Introduction',
  'slide_part.EXERCISE': 'Exercise',
  'slide_part.THEORY': 'Theory',
  'slide_part.CLOSURE': 'Closure',


  // Admin
  'admin.logo': 'Logo',
  'admin.name': 'Name',
  'admin.firstname': 'First name',
  'admin.lastname': 'Last name',
  'admin.stats': 'Stats',
  'admin.users': 'Users',
  'admin.description': 'Description',
  'admin.organizations': 'Organizations',
  'admin.organizations.filter': 'What organization are you looking for?',
  'admin.groups': 'Groups',
  'admin.groups.filter': 'What group are you looking for?',
  'admin.groups.add-group': 'Add group',
  'admin.groups.add-group.success': 'Group added!',
  'admin.groups.edit-group': 'Edit group',
  'admin.groups.edit-group.success': 'Group edited!',
  'admin.groups.remove-group.success': 'Group removed!',

  // Multiselect list
  available: 'Available',
  added: 'Added',
  'looking-for': 'Who are you looking for?',

  // Filterbar
  'filterbar.clear': 'Clear filters',
  'filterbar.show': 'Show filters',
  'filterbar.hide': 'Hide filters',

  // Dropdown
  'dropdown.no_results': 'No results',

  // Slide viewer
  'slide_viewer.teacher_manual': 'Teacher manual',
  'slide_viewer.teacher_manual_empty': 'No additional explanation is needed with this slide',

  // Content selector
  'content-selector.title.default': 'My files',
  'content-selector.add': 'Drop files here...',
  'content-selector.empty_folder': 'This folder seems to be empty',
  'content-selector.drop_items': 'Drop files here',
  'content-selector.url': 'Insert url here',
  'content-selector.image': 'Add image',
  'content-selector.add_external': 'Add external resource',

  // Confirmation dialog
  'confirmation_dialog.title': 'Are you sure?',

  // Content manager
  'content-manager.title': 'My files',

  // Errors
  'errors.label': 'Error',
  'errors.something_went_wrong': 'Something went wrong. Please try again.',
  'errors.user_does_not_exist': 'The username or password is incorrect.',
  'errors.network_error': 'There seems to be trouble connecting to the server... Please try again.',
  'errors.session_expired': 'Your session is expired. Please sign in again.',

  // 404 Page
  'errorpage.title': '404',
  'errorpage.header': 'Oops!',
  'errorpage.subheader': 'Just like unicorns, this page does not exist.',
  'errorpage.message': 'It might have been removed, had its URL changed, or is temporarily unavailable.',
  'errorpage.to_home': 'Back to home',
  'errorpage.refresh': 'Or try again',

  // Content manager actions
  'content-manager.actions.create_folder': 'Create Folder',
  'content-manager.actions.create_file': 'Upload Files',
  'content-manager.actions.rename': 'Rename',
  'content-manager.actions.copy-link': 'Copy link',
  'content-manager.actions.rename-title': 'Rename content',
  'content-manager.actions.rename-title.success': 'The name is renamed',
  'content-manager.actions.overwrite': 'Overwrite File',
  'content-manager.actions.create': 'Create',
  'content-manager.actions.cut': 'Cut',
  'content-manager.actions.copy': 'Copy',
  'content-manager.actions.paste': 'Paste',
  'content-manager.actions.delete': 'Delete',
  'content-manager.actions.delete.pay_attention': 'Pay attention!',
  'content-manager.actions.delete.confirm_text': `This file won't be visible in lesson content anymore.`,
  'content-manager.actions.trashcan': 'Trash',
  'content-manager.actions.undo': 'Undo',
  'content-manager.actions.redo': 'Redo',
  'content-manager.actions.move_to': 'Move to',
  'content-manager.actions.copy_to': 'Copy to',
  'content-manager.actions.create-file.success': 'The file has been created.',
  'content-manager.actions.create-file.file-too-big': 'File exceeds the upload size limit. Please select a smaller file.',
  'content-manager.actions.create-folder.success': 'The folder has been created.',
  'content-manager.actions.remove-file.success': 'The file has been removed.',
  'files.actions-context_menu.copy_here': 'Copy here',
  'files.actions-context_menu.copy_file_to': 'Copy file to',
  'files.actions-context_menu.move_here': 'Move here',
  'files.actions-context_menu.move_file_to': 'Move file to',
  'files.drop_file': 'Add files by dropping them here',
  'content-manager.actions.rename.path-exists': 'This name is already in use in this folder. Do you still want to upload this file?',
  'content-manager.actions.move-file.success': 'File was successfully moved',
  'content-manager.actions.paste-file.success': 'The file has been added.',
  'content-manager.actions.error.only-image': "Please upload an image",
  'content-manager.actions.error.only-video': "Please upload a video",

  // Content manager preview
  'content-manager.preview.path': 'Filename',
  'content-manager.preview.modifiedOn': 'Last modified on',
  'content-manager.preview.modifiedBy': 'Last modified by',
  'content-manager.preview.extension': 'Extension',
  'content-manager.preview.createdOn': 'Created on',
  'content-manager.preview.size': 'Size',
  'content-manager.preview.createdBy': 'Created by',
  'content-manager.preview.mutiple_files': 'Selection',

  // Editor
  'editor.imageplugin.label': 'Insert image',
  'editor.videoplugin.label': 'Insert video',
  'editor.modalplugin.label': 'Insert popup',
  'editor.linkplugin.label': 'Insert link',
  'editor.audioplugin.label': 'Insert audio',
  'editor.iframeplugin.label': 'Insert iframe',
  'editor.iframeplugin.error.iframe.size': 'This field should be less than 1500px',
  'editor.exerciseplugin.label': 'Insert exercise',
  'editor.imageplugin.label-external': 'Insert external image',
  'editor.videoplugin.label-external': 'Insert external video',
  'editor.imageplugin.label-upload': 'Or upload an image',
  'editor.videoplugin.label-upload': 'Or upload a video',
  'editor.editmodalsidebar.title': 'Modal button',
  'editor.editmodalsidebar.label-buttontext': 'Display text',
  'editor.editmodalsidebar.label-text': 'Text',
  'editor.editmodalsidebar.label-modaltitle': 'Title modal',
  'editor.editmodalsidebar.label-modalsize': 'Size modal',
  'editor.editmodalsidebar.label-content': 'Content',
  'editor.editmodalsidebar.label-title': 'Title',
  'editor.audioplugin.label-upload': 'Or upload an audio fragment',

  // Dragarea
  'dragarea.uploading': 'Uploading',
  'dragarea.files': 'files',
  'dragarea.file': 'file',

  // Formik
  'validation.required': 'This field is required',
  'validation.number': 'This field must be a number',
  'validation.url': 'This field must be a valid URL',
  'validation.external_video_url': 'This URL does not seem to contain a Youtube, Vimeo or Dailymotion video',
  'validation.external_audio_url': 'This URL does not seem to contain an audio fragment',
  'validation.external_image_url': 'This URL does not seem to contain an image',
  'validation.empty': 'This field should not be empty',

  // SlideSideBar
  'slide-sidebar.slide_title': 'Slide title',
  'slide-sidebar.slide_type': 'Slide type',
  'slide-sidebar.copy_slide_link': 'Copy link to slide',
  'slide-sidebar.link_copied': 'Link copied!',
  'slide-sidebar.general': 'General',
  'slide-sidebar.files': 'My files',
  'slide-sidebar.steams': 'STEAMS',
  
  // Steams
  'slide-sidebar-steams.chapter': 'Chapter',
  'slide-sidebar-steams.public': 'Select public',
  'slide-sidebar-steams.activity': 'Activity type',
  'slide-sidebar-steams.time': 'Time budget',
  'slide-sidebar-steams.time_text': 'Add timer',
  'slide-sidebar-steams.visibility': 'Visibility',
  'slide-sidebar-steams.visibility_text': 'Make this slide accessible only to the following roles',
  'slide-sidebar-steams.visibility_choose': 'Choose roles',
  'slide-sidebar-steams.general': 'General',
  'slide-sidebar-steams.motivation': 'Motivation',
  'slide-sidebar-steams.motivation.placeholder': 'Motivation...',

  // QuestionAnswerMultipleChoiceSideBar
  'question-answer-multiple-choice-sidebar.is_correct_answer': 'Correct answer?',
  'question-answer-multiple-choice-sidebar.answer': 'Answer',
  'question-answer-multiple-choice-sidebar.explanation': 'Explanation',
  'question-answer-multiple-choice-sidebar.warning.title': 'Warning, this question has been published',
  'question-answer-multiple-choice-sidebar.warning.content': 'Because students may have answered this question, you can only change small things like typing errors. If you would like to do bigger changes, you can duplicate the slide and make those changes there.',
  'question-answer-multiple-choice-sidebar.warning.duplicate': 'Duplicate slide',
  'question-answer-multiple-choice-sidebar.warning.small': 'Small edit',

  // HintsSideBar
  'hints-sidebar.title': 'Hints',
  'hints-sidebar.item.hint_x': 'Hint',
  'hints-sidebar.item.title': 'Title',
  'hints-sidebar.item.content': 'Content',
  'hints-sidebar.add_hint': 'Add hint',

  // TeacherInfoSideBar
  'teacher-info-sidebar.title': 'Teacher info',
  'teacher-info-sidebar.description': 'Here you can make notes for the teacher. This info will never be shared with students.',

  // MultipleChoiceQuestionSideBar
  'multiple-choice-question-sidebar.score_per_correct_answer': 'Number of points per correct answer',
  'multiple-choice-question-sidebar.subtract_per_wrong_answer': 'Subtract per wrong answer',
  'multiple-choice-question-sidebar.max_selectable_answers': 'Max. answering options',
  'multiple-choice-question-sidebar.shuffle_answers': 'Shuffle answers?',
  'multiple-choice-question-sidebar.should_find_all_correct': 'Student has to find them all',

  // OpenQuestionSideBar
  'open-question-sidebar.points': 'Total points',
  'open-question-sidebar.example_solution': 'Example solution',
  'open-question-sidebar.placeholder': 'Placeholder',
  'open-question-sidebar.show_the_answer': 'Show the answer to the student after he has answered',
  'open-question-sidebar.show_the_answer_no': 'No',
  'open-question-sidebar.show_the_answer_yes': 'Yes',

  // LastSavedAt
  'last-saved-at.a_few_seconds_ago': 'a few seconds ago',

  // ExerciseOverview
  'exercise-overview.title': 'Exercises',
  'exercise-overview.new_exercise': 'New exercise',
  'exercise-overview.empty': 'As of yet, no exercises can be found',
  'exercise-overview.empty_page': 'There are no exercises on this page',
  'exercise-overview.empty_page_action': 'Go to the first page',
  'exercise-overview.delete_success': 'Successfully deleted exercise',
  'exercise-overview.delete_fail': 'Error while deleting exercise',
  'exercise-overview.duplicate-success':'The exercise has been succesfully duplicated',
  'exercise-overview.duplicate-error':'There was an error whilst duplicating the exercise',

  // ExerciseCreateDialog
  'exercise-create-dialog.title': 'New exercise',
  'exercise-create-dialog.label.title': 'Title',
  'exercise-version-create-dialog.title': 'Add exercise version',
  'exercise-version-create-dialog.submit': 'Add',
  'exercise-version-update-dialog.title': 'Modify exercise version',
  'exercise-version-update-dialog.submit': 'Modify',
  'exercise-version-dialog.name': 'Name',
  'exercise-version-dialog.versionType': 'Version type',
  'scratch.version.STARTER': 'Starter',
  'scratch.version.SOLUTION': 'Solution',
  'scratch.version.DEMO': 'Demo',
  'scratch.version.EXTRA': 'Extra',
  'scratch.version.OTHER': 'Other',

  // Exercise
  'exercise.error': 'Error whilst loading your selected exercise',
  'exercise.forbidden_error': 'You don\'t have access to this exercise',

  // Scratch
  'scratch.header.upload-exercise': 'Upload exercise',
  'scratch.header.turbo': 'Turbo',
  'scratch.upload-test-plan.success': 'The testplan has been successfully uploaded',
  'scratch.upload-test-plan.error': 'Something went wrong whilest uploading the testplan',
  'scratch.test-plan.error': 'There is no testplan linked to this exercise',
  'scratch.confirmation.content': 'Are you sure you want to reset the exercise?',

  // Scratch gui
  'gui.ftrprf.testresult.test': 'Test',
  'gui.ftrprf.testresult.tests': 'Tests',
  'gui.ftrprf.testresult.show_subtests': 'Show all checks',
  'gui.ftrprf.testresult.hide_subtests': 'Hide all checks',
  'gui.ftrprf.testresult.click_to_test': 'Test your code!',
  'gui.ftrprf.testtab': 'Check (beta)',
  'gui.ftrprf.testtab.loading': 'Checking your code...',
  'gui.ftrprf.translationtab.translation_code': 'Translation code',
  'gui.ftrprf.translationtab.translate': 'Translate',
  'gui.ftrprf.translationtab.chosen_language': 'Chosen language',
  'gui.ftrprf.translationtab.add': 'Add',
  'gui.ftrprf.translationtab.edit': 'Edit',
  'gui.ftrprf.translationtab.add_translation': 'Add new translation',
  'gui.ftrprf.translationtab.edit_translation': 'Edit translation',
  'gui.ftrprf.translationtab.dictionary': 'Dictionary',
  'gui.ftrprf.translationtab.translations': 'Translations',
  'gui.ftrprf.translationtab.groupname': 'Group name',

  //File Manager
  'file.manager.abbr.not-applicable':'N/A',
  'file.manager.buttons.title': 'Folder and file actions',
  'file.manager.clear-search':'Clear search',
  'file.manager.content-moved':'Content moved',
  'file.manager.content-renamed':'Rename succesful',
  'file.manager.copy-link':'Copy link',
  'file.manager.create-folder':'Create folder',
  'file.manager.created-on':'Created on',
  'file.manager.creator':'Creator',
  'file.manager.currently-searching-in':'Currently searching in',
  'file.manager.cut':'Cut',
  'file.manager.delete.confirmation':'Are you sure you want to delete the selected content?',
  'file.manager.delete.failure':'Something went wrong while deleting the content.',
  'file.manager.delete.success':'Content deleted',
  'file.manager.delete.success.partial':"All unused files & folders have been deleted.  Some files are still in use, those haven't been deleted.  Remove their usages in order to delete them.",
  'file.manager.empty-folder':'Empty folder',
  'file.manager.file-detail': 'File information',
  'file.manager.file-size':'Size',
  'file.manager.files-uploaded':'File(s) uploaded',
  'file.manager.folder-name':'Folder name',
  'file.manager.folderview.title': 'Folder detail',
  'file.manager.go-to-folder':'Go to parent folder',
  'file.manager.last-modified-by':'Last modified by',
  'file.manager.last-modified-on':'Last modified on',
  'file.manager.modifiedBy':'Modified by',
  'file.manager.modifiedOn':'Modified on',
  'file.manager.move-confirmation':'Are you sure you want to move the selected content?',
  'file.manager.new-folder':'New folder',
  'file.manager.new-folder-confirm':'Make',
  'file.manager.not-applicable':'Not applicable',
  'file.manager.not-used':'Not used',
  'file.manager.not-used-lesson':"This file isn't being used in a lesson.",
  'file.manager.paste':'Paste',
  'file.manager.path':'Path',
  'file.manager.search-in-folder':'Search in current folder',
  'file.manager.search-in-root':'search in "my files" instead',
  'file.manager.search-no-results':'Nothing found',
  'file.manager.select-all':'Select all',
  'file.manager.slide':'Slide',
  'file.manager.slides':'Slides',
  'file.manager.tree.title': 'File tree',
  'file.manager.upload':'Upload files',
  'file.manager.usage':'Used in:',
  'file.manager.use-old-file-manager':'Use the old file manager',
  'file.manager.use-new-file-manager':'Use the new file manager',

  // External
  ...externalEn,
};