import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { INITIALIZE_CONTENT_NAVIGATION_TREE_FROM_PATH } from '../../../../api/filemanager';

const useContentNavigationTreeFromPath = (path, enabled = false) => {
  const [fetch, { data, loading, error }] = useLazyQuery(
    INITIALIZE_CONTENT_NAVIGATION_TREE_FROM_PATH,
    {
      fetchPolicy: 'network-only',
    },
  );
  const parsedData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return JSON.parse(data.initializeContentNavigationTreeFromPath.content);
  }, [data]);

  useEffect(() => {
    if (enabled) {
      fetch({
        variables: {
          path,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  return { data: parsedData, loading, error, fetch };
};

export default useContentNavigationTreeFromPath;
