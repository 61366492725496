import { useContext } from 'react';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { FileManagerContext } from '../FileManagerContextProvider';

import ContentPreview from './ContentPreview';
import FileManagerButtons from './FileManagerButtons';
import FolderDetail from './FolderDetail';

const FolderView = () => {
  const t = useFormatMessage();
  const { activeFolder, selectedContent, isSearching, setActiveFolder } =
    useContext(FileManagerContext);

  const selectedContentItem = selectedContent?.[0];

  return (
    <div className="flex-auto flex flex-col w-3/4 divide-y-4 divide-black bg-white">
      <FileManagerButtons />
      <div className="flex divide-x-4 divide-black h-full">
        <h2 className="sr-only">{t('file.manager.folderview.title')}</h2>
        <div className="flex-auto w-[70%] overflow-auto">
          {isSearching && (
            <div className="text-center my-2">
              <p className="">
                {t('file.manager.currently-searching-in')} &quot;
                {activeFolder?.name || t('header.files')}
                &quot;&nbsp;
              </p>
              {!activeFolder ||
                (activeFolder?.id !== null && (
                  <button
                    className="hover:cursor-pointer underline"
                    onClick={() => setActiveFolder()}
                  >
                    {t('file.manager.search-in-root')}
                  </button>
                ))}
            </div>
          )}
          <FolderDetail />
        </div>
        {selectedContent?.length === 1 && selectedContentItem && (
          <ContentPreview
            key={`content-${selectedContentItem?.id}`}
            content={selectedContentItem}
          />
        )}
      </div>
    </div>
  );
};

export default FolderView;
