import { VIEW_MODES } from '@ftrprf/tailwind-components';

import ContentEditorContainer from './ContentEditorContainer';

const ExamContainer = (props) => {
  const insertSlideBelow = ({
    sequence,
    content,
    insertSlideMutate,
    parameters = {},
  }) => {
    const newSlide = {
      lessonContentId: content.id,
      sequence,
      content: '',
      title: '',
      viewModes: [VIEW_MODES.CLASSICAL],
      groupRoles: [],
    };

    return insertSlideMutate({ ...newSlide, ...parameters });
  };
  return (
    <ContentEditorContainer
      hasViewModeSelector={false}
      insertSlideBelow={insertSlideBelow}
      {...props}
    />
  );
};

export default ExamContainer;
