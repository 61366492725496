import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useFileUpload from '../../hooks/graphql/file-manager/mutations/useFileUpload';
import useContentNavigationTree from '../../hooks/graphql/file-manager/queries/useContentNavigationTree';
import useFileSearch from '../../hooks/graphql/file-manager/queries/useFileSearch';
import usePathStack from '../../hooks/usePathStack';

import { FILE_MANAGER } from '../../utils/constants/urls';

export const FileManagerContext = createContext({});

const FileManagerContextProvider = ({ children }) => {
  const { relativePath } = usePathStack(FILE_MANAGER.substring(1));

  const [activeFolder, setActiveFolder] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [activePath, setActivePath] = useState();
  const [selectedContent, setSelectedContent] = useState([]);
  const [fileTreeKey, setFileTreeKey] = useState(new Date());

  const {
    data: currentFolderContent,
    loading: currentFolderContentLoading,
    fetch: refetchFolderContent,
  } = useContentNavigationTree(!!activeFolder, activeFolder?.id, 1, false);

  const {
    data: searchResults,
    loading: searchLoading,
    search: searchContent,
  } = useFileSearch();

  const { uploadFiles: upload, loading: isFileUploadLoading } = useFileUpload();

  const refetchCurrentFolderContent = useCallback(
    (folderId = activeFolder?.id) => {
      refetchFolderContent({
        variables: {
          startFolderId: folderId || null,
          levels: 1,
          onlyFolders: false,
        },
        fetchPolicy: 'network-only',
      });
    },
    [activeFolder?.id, refetchFolderContent],
  );

  const uploadFiles = useCallback(
    async (files) => {
      await upload({
        variables: {
          parentId: activeFolder?.id || null,
          files,
        },
      });

      refetchCurrentFolderContent();
    },
    [activeFolder?.id, refetchCurrentFolderContent, upload],
  );

  const reloadFileTree = () => {
    setFileTreeKey(new Date());
  };

  useEffect(() => {
    setActivePath(activeFolder?.path || relativePath.replace(/\/$/, '') || '/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFolder?.path, activePath]);

  useEffect(() => {
    history.pushState(null, '', FILE_MANAGER + activePath);
    setSelectedContent([]);
  }, [activePath]);

  const context = useMemo(
    () => ({
      activeFolder,
      activePath,
      selectedContent,
      currentFolderContent,
      currentFolderContentLoading,
      searchResults,
      searchLoading,
      isSearching,
      fileTreeKey,
      isFileUploadLoading,
      reloadFileTree,
      setIsSearching,
      searchContent,
      setActiveFolder,
      setActivePath,
      setSelectedContent,
      refetchCurrentFolderContent,
      uploadFiles,
    }),
    [
      activeFolder,
      activePath,
      currentFolderContent,
      currentFolderContentLoading,
      fileTreeKey,
      isFileUploadLoading,
      isSearching,
      refetchCurrentFolderContent,
      searchContent,
      searchLoading,
      searchResults,
      selectedContent,
      uploadFiles,
    ],
  );

  return (
    <FileManagerContext.Provider value={context}>
      {children}
    </FileManagerContext.Provider>
  );
};

export default FileManagerContextProvider;
