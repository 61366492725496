import { useContext, useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { FolderIcon } from '@heroicons/react/24/solid';

import { Loader } from '@ftrprf/tailwind-components';

import useContentNavigationTree from '../../../hooks/graphql/file-manager/queries/useContentNavigationTree';

import c from '../../../utils/functions/c';

import { FileManagerContext } from '../FileManagerContextProvider';

const TreeFolderCollapsable = ({ folder, isOpen: defaultIsOpen }) => {
  const {
    id,
    name,
    hasChildren: hasSubFolders,
    children: subFolders,
    path,
  } = folder;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const { activeFolder, activePath, setActiveFolder, setIsSearching } =
    useContext(FileManagerContext);

  const {
    data: additionalSubFolders,
    fetch: fetchAdditionalFolders,
    loading: isLoadingAdditionalSubFolders,
  } = useContentNavigationTree(false);

  useEffect(() => {
    if (activePath === path && activeFolder?.path !== path) {
      setActiveFolder(folder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (`${activePath}/`?.includes(`${path}/`)) {
      setIsOpen(true);
    }
  }, [
    activeFolder?.id,
    activeFolder?.path,
    activePath,
    folder,
    folder.id,
    id,
    path,
    setActiveFolder,
  ]);

  useEffect(() => {
    if (isOpen && hasSubFolders && subFolders?.length === 0) {
      fetchAdditionalFolders({
        variables: {
          startFolderId: id,
          levels: 1,
          onlyFolders: true,
        },
      });
    }
  }, [fetchAdditionalFolders, hasSubFolders, id, isOpen, subFolders?.length]);

  return (
    <>
      <div className="flex clip">
        <button
          className={c('mr-1', hasSubFolders || 'invisible')}
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <FaChevronRight
            className={c(
              'w-3 cursor-pointer',
              isOpen
                ? 'transform rotate-90 transition duration-150'
                : 'transition duration-150',
            )}
          />
        </button>

        <button
          className={c('flex px-2', activeFolder?.id === id && 'bg-gray-200')}
          onClick={() => {
            setActiveFolder(folder);
            setIsSearching(false);
          }}
        >
          <FolderIcon className="w-5 mr-1" />
          <span className="truncate">{name}</span>
        </button>
      </div>
      {isOpen && (
        <div className="ml-4">
          {isLoadingAdditionalSubFolders && (
            <div className="w-5 ml-6">
              <Loader className="w-5" />
            </div>
          )}
          {[...(subFolders || []), ...(additionalSubFolders || [])].map(
            (subFolder) => (
              <TreeFolderCollapsable key={subFolder.id} folder={subFolder} />
            ),
          )}
        </div>
      )}
    </>
  );
};

export default TreeFolderCollapsable;
