import { useCallback, useRef } from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { QUESTION_TYPES } from '@ftrprf/tailwind-components';

import useFieldMutation from '../../../hooks/graphql/useFieldMutation';

import { CREATE_HINT, REMOVE_HINT } from '../../../api/hint';
import {
  CREATE_QUESTION_MULTIPLE_CHOICE,
  CREATE_QUESTION_OPEN,
  REMOVE_QUESTION,
} from '../../../api/question';
import { UPDATE_SLIDE } from '../../../api/slide';

import { GRAPHQL_QUESTION_TYPES } from '../../../utils/constants/questionTypes';
import isArray from '../../../utils/functions/isArray';
import { lastSavedAtAtom, slideAtom } from '../utils/atom';

const read = (client, id) =>
  client.readFragment({
    id: `Slide:${id}`,
    fragment: gql`
      fragment MySlide on Slide {
        id
        title
        content
        sequence
        viewModes
        info
        motivation
        groupRoles {
          id
          role
        }
        questions {
          id
          type
          publishedAt
          ... on QuestionOpen {
            value
            scorePerCorrectAnswer
            placeholder
            solution
            questionAnswerOpen {
              id
              explanation
              optional
            }
          }
          ... on QuestionMultipleChoice {
            value
            scorePerCorrectAnswer
            subtractPerWrongAnswer
            maxSelectableAnswers
            shouldFindAllCorrect
            shuffleAnswers
            questionAnswersMultipleChoice {
              id
              value
              correct
              explanation
            }
          }
        }
        hints {
          id
          title
          content
        }
      }
    `,
  });

const useSlide = (id) => {
  const ref = useRef();
  const client = useApolloClient();

  const [mutate] = useFieldMutation(UPDATE_SLIDE, 'Slide', [
    'id',
    'content',
    'info',
    'viewModes',
    'title',
    'sequence',
    'motivation',
    'groupRoles',
  ]);

  const [slide, setSlide] = useRecoilState(slideAtom(id));
  const setLastSavedAt = useSetRecoilState(lastSavedAtAtom);

  const refetch = () => {
    setSlide(read(client, id));
  };

  const debounceMutation = useCallback(
    (options) => {
      if (ref.current) {
        clearTimeout(ref.current);
      }

      ref.current = setTimeout(() => {
        mutate(options).then(() => setLastSavedAt(new Date()));
      }, 250);
    },
    [mutate, setLastSavedAt],
  );

  if (slide === null) {
    refetch();
  }

  const [createHintMutate] = useMutation(CREATE_HINT, {
    update(cache, { data: { createHint } }) {
      cache.modify({
        id: client.cache.identify(slide),
        fields: {
          hints(existingRefs = []) {
            return [
              ...existingRefs,
              {
                __ref: client.cache.identify({
                  __typename: 'Hint',
                  id: createHint.id,
                }),
              },
            ];
          },
        },
      });
    },
  });

  const [removeHintMutate] = useMutation(REMOVE_HINT, {
    update(cache, { data: { removeHint } }) {
      cache.modify({
        id: client.cache.identify(slide),
        fields: {
          hints(existingRefs = [], { readField }) {
            return existingRefs.filter(
              (ref) => removeHint.id !== readField('id', ref),
            );
          },
        },
      });
    },
  });

  const [removeQuestionMutate] = useMutation(REMOVE_QUESTION, {
    update(cache, { data: { removeQuestion } }) {
      cache.modify({
        id: client.cache.identify(slide),
        fields: {
          questions(existingRefs = [], { readField }) {
            return existingRefs.filter(
              (ref) => removeQuestion.id !== readField('id', ref),
            );
          },
        },
      });
    },
  });

  const [createOpenQuestionMutate] = useMutation(CREATE_QUESTION_OPEN, {
    update(cache, { data: { createQuestionOpen } }) {
      cache.modify({
        id: client.cache.identify(slide),
        fields: {
          questions(existingRefs = []) {
            return [
              ...existingRefs,
              {
                __ref: client.cache.identify({
                  __typename: GRAPHQL_QUESTION_TYPES[QUESTION_TYPES.OPEN],
                  id: createQuestionOpen.id,
                }),
              },
            ];
          },
        },
      });
    },
  });

  const [createMultipleChoiceQuestionMutate] = useMutation(
    CREATE_QUESTION_MULTIPLE_CHOICE,
    {
      update(cache, { data: { createQuestionMultipleChoice } }) {
        cache.modify({
          id: client.cache.identify(slide),
          fields: {
            questions(existingRefs = []) {
              return [
                ...existingRefs,
                {
                  __ref: client.cache.identify({
                    __typename:
                      GRAPHQL_QUESTION_TYPES[QUESTION_TYPES.MULTIPLE_CHOICE],
                    id: createQuestionMultipleChoice.id,
                  }),
                },
              ];
            },
          },
        });
      },
    },
  );

  const createOpenQuestion = () => {
    return createOpenQuestionMutate({
      variables: {
        slideId: slide.id,
      },
    }).then(refetch);
  };

  const createMultipleChoiceQuestion = () => {
    return createMultipleChoiceQuestionMutate({
      variables: {
        slideId: slide.id,
      },
    }).then(refetch);
  };

  const removeQuestion = (id) => {
    return removeQuestionMutate({
      variables: {
        id,
      },
    }).then(refetch);
  };

  const createHint = () => {
    return createHintMutate({
      variables: {
        slideId: slide.id,
        title: '',
        content: '',
      },
    }).then(refetch);
  };

  const removeHint = (id) => {
    return removeHintMutate({
      variables: {
        id,
      },
    }).then(refetch);
  };

  const delBadKeys = useCallback((obj) => {
    if (!obj) return null;
    if (!(obj instanceof Object)) return obj;
    if (isArray(obj)) {
      return obj.map((o) => delBadKeys(o));
    }
    let newobj = {};
    for (const key in obj) {
      if (key !== '__typename') {
        const r = delBadKeys(obj[key]);
        if (r) newobj[key] = r;
      }
    }
    return newobj;
  }, []);
  const update = useCallback(
    (variables, mutate = true) => {
      setSlide((l) => {
        let newValue = { ...l, ...variables };

        newValue = delBadKeys(newValue);

        if (mutate) {
          debounceMutation(newValue);
        }
        return newValue;
      });
    },
    [debounceMutation, setSlide, delBadKeys],
  );

  return {
    slide,
    setSlide,
    update,
    createOpenQuestion,
    createMultipleChoiceQuestion,
    removeQuestion,
    createHint,
    removeHint,
    refetch,
  };
};

export default useSlide;
