import { useMutation } from '@apollo/client';

import { CREATE_FOLDER } from '../../../../api/filemanager';

const useCreateFolder = () => {
  const [createFolder, { loading, error }] = useMutation(CREATE_FOLDER);

  return { createFolder, loading, error };
};

export default useCreateFolder;
