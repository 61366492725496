import { createElement, useContext } from 'react';
import parsePlugin from 'rehype-parse';
import rehypeReact from 'rehype-react';
import unified from 'unified';

import Link from './SlideComponents/Link';
import ModalButton from './SlideComponents/ModalButton';

import SlideViewerBaseSlide from './SlideViewerBaseSlide';
import { SlideViewerContext } from './SlideViewerContextProvider';

const IFrame = (props) => {
  const { groupId } = useContext(SlideViewerContext);

  let { src } = props;

  if (groupId) {
    src += '?groupId=' + groupId;
  }

  return <iframe {...props} src={src} />;
};

const processor = unified()
  .use(parsePlugin, { fragment: true })
  .use(rehypeReact, {
    createElement,
    components: {
      ['a']: Link,
      ['button']: ModalButton,
      ['iframe']: IFrame,
    },
  });

const SlideViewerTextSlide = ({ value, classNames }) => {
  return (
    <SlideViewerBaseSlide
      value={value}
      processor={processor}
      classNames={classNames}
    />
  );
};

export default SlideViewerTextSlide;
