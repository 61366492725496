import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';

import { NotificationBar } from '@ftrprf/tailwind-components';

import AdminContainer from '../pages/Admin/AdminContainer';
import ExamContentEditorContainer from '../pages/ContentEditor/ExamContainer';
import LessonContentEditorContainer from '../pages/ContentEditor/LessonContainer';
import ContentManagerContainer from '../pages/ContentManager/ContentManagerContainer';
import ContentOverviewContainer from '../pages/ContentOverview/ContentOverviewContainer';
import ErrorPage from '../pages/ErrorPage';
import ExerciseContainer from '../pages/Exercise/ExerciseContainer';
import ExerciseVersionContainer from '../pages/Exercise/ExerciseVersionContainer';
import ExerciseOverviewContainer from '../pages/ExerciseOverview/ExerciseOverviewContainer';
import CallbackContainer from '../pages/Login/CallbackContainer';
import LoginContainer from '../pages/Login/LoginContainer';
import Logout from '../pages/Logout/Logout';
import SlideViewerContainer from '../pages/SlideViewer/SlideViewerContainer';

import FileManagerContainer from '../components/FileManager/FileManagerContainer';

import { UserContext } from '../providers/UserProvider';

import { CURRENT_DOMAIN_SETTINGS } from '../utils/constants/domains';
import { AUTHORIZATION_TOKEN } from '../utils/constants/localStorage';
import * as URLS from '../utils/constants/urls';
import isBetaUser from '../utils/isBetaUser';

import FullPageLayout from './layouts/FullPageLayout';
import PrivateRoute from './routes/PrivateRoute';
import RestrictedRoute from './routes/RestrictedRoute';

import Bootstrap from './Bootstrap';

const PrivateApp = () => {
  const [loaded, setLoaded] = useState(false);
  const { user } = useContext(UserContext);

  if (!loaded) {
    return <Bootstrap onLoaded={() => setLoaded(true)} />;
  }

  return (
    <Switch>
      <PrivateRoute exact path={URLS.ROOT}>
        <Redirect to={URLS.LESSONS} />
      </PrivateRoute>

      <PrivateRoute
        exact
        path={URLS.LESSONS}
        component={ContentOverviewContainer}
      />

      {isBetaUser(user) && (
        <PrivateRoute
          exact
          path={URLS.LESSON_TEMPLATES}
          component={ContentOverviewContainer}
        />
      )}

      <PrivateRoute
        exact
        path={URLS.EXAMS}
        component={ContentOverviewContainer}
      />

      <PrivateRoute
        exact
        path={URLS.EXERCISES}
        component={ExerciseOverviewContainer}
      />

      <PrivateRoute
        layout={FullPageLayout}
        exact
        path={`${URLS.EXERCISE}/:id`}
        component={ExerciseContainer}
      />

      <PrivateRoute
        layout={FullPageLayout}
        exact
        path={`${URLS.EXERCISE}/:id/version/:versionId`}
        component={ExerciseContainer}
      />

      <PrivateRoute
        layout={FullPageLayout}
        exact
        path={`${URLS.EXERCISE}/version/:versionId`}
        component={ExerciseVersionContainer}
      />

      <PrivateRoute
        layout={FullPageLayout}
        exact
        path={`${URLS.LESSON}/:lessonContentId/edit/:slideId?`}
        component={LessonContentEditorContainer}
      />

      <PrivateRoute
        layout={FullPageLayout}
        exact
        path={`${URLS.EXAM}/:lessonContentId/edit/:slideId?`}
        component={ExamContentEditorContainer}
      />

      <PrivateRoute
        layout={FullPageLayout}
        exact
        path={`${URLS.LESSON_TEMPLATE}/:lessonContentId/edit/:slideId?`}
        component={LessonContentEditorContainer}
      />

      <PrivateRoute
        exact
        path={`${URLS.CONTENT}/:lessonContentId/:viewMode`}
        component={SlideViewerContainer}
      />

      <PrivateRoute
        exact
        path={`${URLS.CONTENT}/:lessonContentId/:viewMode/:slideId`}
        component={SlideViewerContainer}
      />

      <PrivateRoute path={URLS.FILES} component={ContentManagerContainer} />
      <PrivateRoute path={URLS.FILE_MANAGER} component={FileManagerContainer} />
      <PrivateRoute path={URLS.ADMIN} component={AdminContainer} />

      <PrivateRoute component={ErrorPage} />
    </Switch>
  );
};

const RestrictedApp = ({ setIsPossiblySignedIn }) => {
  // This hook is needed to make sure this component rerenders every time
  // when the location changes. This way, we can be notified when a
  // AUTHORIZATION_TOKEN is added. We may add this logic to the <App />
  // itself but that would cause a rerender of the <App /> every time the route
  // changes
  const location = useLocation();

  const isPossiblySignedIn = !!localStorage.getItem(AUTHORIZATION_TOKEN);

  useEffect(() => {
    if (isPossiblySignedIn) {
      setIsPossiblySignedIn(true);
    }
  }, [location, isPossiblySignedIn, setIsPossiblySignedIn]);

  // Don't render anything when isPossiblySignedIn is true because. When
  // isPossiblySignedIn === true, we need to serve the PrivateApp.
  if (isPossiblySignedIn) {
    return null;
  }

  return (
    <Switch>
      <RestrictedRoute
        exact
        path={URLS.MANUAL_LOGIN}
        component={LoginContainer}
      />
      <Route
        component={() => {
          window.location.href = CURRENT_DOMAIN_SETTINGS.appUrl;
          return null;
        }}
      />
    </Switch>
  );
};

const App = () => {
  // A user is possibly signed in when there is a token in localStorage
  // To be 100% sure the user is logged in, the Bootstrap component should
  // be rendered first that will fetch the user with the token from localStorage
  const [isPossiblySignedIn, setIsPossiblySignedIn] = useState(
    !!localStorage.getItem(AUTHORIZATION_TOKEN),
  );

  return (
    <div className="antialiased w-full h-full flex flex-col flex-grow items-center relative">
      <NotificationBar />

      <Switch>
        <Route
          exact
          path={URLS.CALLBACK}
          render={() => <CallbackContainer />}
        />

        <Route path={URLS.LOGOUT} render={() => <Logout />} />

        {isPossiblySignedIn && <PrivateApp />}
        {!isPossiblySignedIn && (
          <RestrictedApp setIsPossiblySignedIn={setIsPossiblySignedIn} />
        )}
      </Switch>
    </div>
  );
};

export default App;
