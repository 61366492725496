import {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import { useFileDownload } from '@ftrprf/hooks';
import { NotificationContext } from '@ftrprf/tailwind-components';

import { LanguageContext } from '../../providers/LanguageProvider';
import { UserContext } from '../../providers/UserProvider';

import useExerciseMutation from '../../hooks/graphql/useExerciseMutation';
import useExerciseQuery from '../../hooks/graphql/useExerciseQuery';
import useExerciseTestPlan from '../../hooks/graphql/useExerciseTestPlan';
import useExerciseTestPlanMutation from '../../hooks/graphql/useExerciseTestPlanMutation';
import useExerciseVersionMutation from '../../hooks/graphql/useExerciseVersionMutation';
import useTranslationGroupNames from '../../hooks/graphql/useTranslationGroupNames';
import useFormatMessage from '../../hooks/useFormatMessage';
import useParams from '../../hooks/useParams';

import {
  FIND_EXERCISE_TEST_RESULT,
  RUN_SCRATCH_JUDGE,
} from '../../api/exercise';
import {
  CREATE_TRANSLATION,
  FIND_ALL_TRANSLATIONS,
  UPDATE_TRANSLATION,
} from '../../api/translations';

import { EXERCISE } from '../../utils/constants/urls';
import isBetaUser from '../../utils/isBetaUser';

import { ReactComponent as Loader } from '../../assets/vectors/logo-FTRPRF-animated-lines.svg';

const Exercise = lazy(() => import('./Exercise'));

const STATUS = {
  LOADING_TESTS: 'LOADING_TESTS',
};

const ExerciseContainer = () => {
  const t = useFormatMessage();
  const history = useHistory();
  const [status, setStatus] = useState();
  const { id, versionId, language: languageURL } = useParams();
  const apolloClient = useApolloClient();
  const { language } = useContext(LanguageContext);
  const { user } = useContext(UserContext);
  const [testResults, setTestResults] = useState([]);
  const { addNotification } = useContext(NotificationContext);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const {
    loading: loadingExercise,
    error: errorLoading,
    exercise,
    setBlob,
    setTestPlanId,
  } = useExerciseQuery(id, versionId);
  const [updateExercise, { loading: saving }] = useExerciseMutation();
  const {
    createScratchExerciseVersion: [createExerciseVersion],
    updateScratchExerciseVersion: [updateExerciseVersion],
    removeScratchExerciseVersion: [removeExerciseVersion],
  } = useExerciseVersionMutation();
  const {
    createExerciseTestPlan,
    createExerciseTestPlanLoading: uploadingTestPlan,
  } = useExerciseTestPlanMutation();
  const fileDownload = useFileDownload();
  const { loading: isExerciseTestPlanLoading, fetchExerciseTestPlan } =
    useExerciseTestPlan({
      exerciseVersionId: exercise?.versionId,
      onCompleted: (testPlan) => fileDownload(testPlan.blobUri, testPlan.name),
    });
  const {
    data: translationGroups,
    isLoading: isTranslationGroupsLoading,
    refetch: refetchTranslationGroups,
  } = useTranslationGroupNames();

  //// START TRANSLATIONS ////
  const [translationFilters, setTranslationFilters] = useState({});
  const [translationsLoading, setTranslationsLoading] = useState(true);
  const [translationsPage, setTranslationsPage] = useState(0);
  const [translationsPages, setTranslationsPages] = useState(0);
  const [translations, setTranslations] = useState([]);

  // Translations: GET
  const getTranslations = () => {
    setTranslationsLoading(true);
    apolloClient
      .query({
        query: FIND_ALL_TRANSLATIONS,
        variables: {
          page: translationsPage,
          sort: { modifiedOn: 'DESC' },
          size: 10,
          filter: Object.entries(translationFilters)
            .map(([key, value]) => ({
              key,
              value,
              operation: '~',
            }))
            .filter(
              (f) =>
                f.value !== null && f.value !== undefined && f.value !== '',
            ),
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        setTranslations(response.data?.findAllTranslations.content);
        setTranslationsPages(response.data?.findAllTranslations.pages);
      })
      .catch((e) => {
        addNotification({
          type: 'error',
          content: e.msg,
        });
      })
      .finally(() => {
        setTranslationsLoading(false);
      });
  };

  // Translations: CREATE
  const onTranslationCreate = (values) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: CREATE_TRANSLATION,
          variables: {
            id: values.id,
            valueNl: values.valueNl,
            valueEn: values.valueEn,
            groupName: values.groupName,
          },
        })
        .then((data) => {
          addNotification({
            type: 'success',
            content: 'Translation added',
          });
          refetchTranslationGroups();
          getTranslations();
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  // Translations: UPDATE
  const onTranslationUpdate = (values) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: UPDATE_TRANSLATION,
          variables: {
            id: values.id,
            valueNl: values.valueNl,
            valueEn: values.valueEn,
            groupName: values.groupName,
          },
        })
        .then((data) => {
          addNotification({
            type: 'success',
            content: 'Translation updated',
          });
          refetchTranslationGroups();
          getTranslations();
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  // Translations: Refetch transations on page or filter changes
  useEffect(() => {
    getTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationFilters, translationsPage]);

  // Translations: Update Filters
  const onUpdateTranslationsFilter = (newFilters) => {
    const combinedFilters = { ...translationFilters, ...newFilters };
    setTranslationFilters(combinedFilters);
    setTranslationsPage(0);
  };

  //// END TRANSLATIONS ////

  const save = useCallback(
    ({ exerciseBlob, canvasBlob }) => {
      const { titleNL, titleEN, versionId } = exercise;

      return updateExercise({
        variables: {
          id,
          titleNL,
          titleEN,
          file: exerciseBlob,
          versionId,
          thumbnail: canvasBlob,
        },
      });
    },
    [exercise, id, updateExercise],
  );

  const onUploadTestPlan = useCallback(
    ({ exerciseVersionId, file }) => {
      createExerciseTestPlan({
        exerciseVersionId,
        name: `testplan${exerciseVersionId}.js`,
        file,
        setTestPlanId,
      });
    },
    [createExerciseTestPlan, setTestPlanId],
  );

  const createVersion = useCallback(
    ({ name, versionType }) => {
      return createExerciseVersion({
        variables: {
          exerciseId: Number(id),
          exerciseVersionIdToCopy: Number(exercise.versionId),
          name,
          versionType,
        },
      }).then(({ data }) => {
        return exercise
          .refetch()
          .then(() => data.createScratchExerciseVersion.id);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createExerciseVersion, exercise, history, id],
  );

  const removeVersion = useCallback(
    (id) => {
      return removeExerciseVersion({
        variables: { id },
      }).then(() => {
        return exercise.refetch().then(({ data }) => {
          history.replace(
            `${EXERCISE}/${exercise.id}/version/${data.findExercise.versions[0].id}/${languageURL}`,
          );
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exercise, history, removeExerciseVersion],
  );

  const updateVersion = useCallback(
    ({ name, versionId, versionType }) => {
      updateExerciseVersion({
        variables: {
          versionId: Number(versionId),
          name,
          versionType,
        },
      });
    },
    [updateExerciseVersion],
  );

  const onTest = useCallback(
    ({ exerciseBlob, enableTestButton }) => {
      setStatus(STATUS.LOADING_TESTS);
      setTestResults([]);

      apolloClient
        .mutate({
          mutation: RUN_SCRATCH_JUDGE,
          variables: {
            exerciseVersionId: versionId,
            file: exerciseBlob,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((data) => {
          const sessionId = data?.data?.runScratchJudge?.id;
          const startOfQuery = Date.now();

          if (!sessionId) {
            setStatus();
            return addNotification({
              type: 'error',
              content: t('scratch.test-plan.error'),
            });
          }

          const queryResultsEvery5Sec = setInterval(() => {
            apolloClient
              .mutate({
                mutation: FIND_EXERCISE_TEST_RESULT,
                variables: {
                  sessionId,
                },
              })
              .then(({ data: { findScratchJudgeResult } }) => {
                if (findScratchJudgeResult?.result?.length > 0) {
                  clearInterval(queryResultsEvery5Sec);
                  setStatus();
                  setTestResults(JSON.parse(findScratchJudgeResult.result));
                  enableTestButton();
                }
              })
              .catch(() => {
                clearInterval(queryResultsEvery5Sec);
                setStatus();
                enableTestButton();
              });

            if (Date.now() - startOfQuery > 60000) {
              clearInterval(queryResultsEvery5Sec);
              setStatus();
              enableTestButton();
            }
          }, 5000);
        });
    },
    [addNotification, apolloClient, t, versionId],
  );

  if (errorLoading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        {t('exercise.error')}
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Loader className="w-32" />
        </div>
      }
    >
      <Exercise
        language={language}
        exercise={{
          ...exercise,
          testResults,
          setStatus,
        }}
        isSaving={saving}
        isLoading={loadingExercise}
        isLoadingTests={status === STATUS.LOADING_TESTS}
        test={onTest}
        save={save}
        setBlob={setBlob}
        createVersion={createVersion}
        removeVersion={removeVersion}
        updateVersion={updateVersion}
        onUploadTestPlan={onUploadTestPlan}
        uploadingTestPlan={uploadingTestPlan}
        onDownloadTestPlan={fetchExerciseTestPlan}
        downloadTestPlanLoading={isExerciseTestPlanLoading}
        hasTestPlanOptions={isBetaUser(user)}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        canTranslate={isBetaUser(user)}
        translations={translations}
        translationGroups={translationGroups}
        onUpdateTranslationsFilter={onUpdateTranslationsFilter}
        translationsLoading={translationsLoading || isTranslationGroupsLoading}
        onTranslationCreate={onTranslationCreate}
        onTranslationUpdate={onTranslationUpdate}
        translationsPages={translationsPages}
        setTranslationsPage={setTranslationsPage}
        translationsPage={translationsPage}
        translationFilters={translationFilters}
      />
    </Suspense>
  );
};

export default ExerciseContainer;
