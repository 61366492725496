import { useContext } from 'react';
import { DocumentIcon, FolderIcon } from '@heroicons/react/24/solid';

import { CheckBox } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../hooks/useFormatMessage';

import c from '../../../utils/functions/c';
import { fileDateFormatter } from '../utils/fileDateFormatter';
import { fileNameWithExtension } from '../utils/fileNameWithExtension';

import { FileManagerContext } from '../FileManagerContextProvider';

const FolderDetailItem = ({ content }) => {
  const t = useFormatMessage();

  const {
    id,
    name,
    isFile,
    thumbnailSmall,
    modifiedBy,
    modifiedOn,
    path,
    slides,
  } = content;

  const {
    selectedContent,
    setActiveFolder,
    setSelectedContent,
    isSearching,
    setIsSearching,
  } = useContext(FileManagerContext);

  const onCheckboxChange = (value) => {
    if (value) {
      setSelectedContent((prev) => [...prev, content]);
      return;
    }
    setSelectedContent((prev) => prev.filter((content) => content.id !== id));
  };

  const onRowClick = () => {
    if (isFile) {
      setSelectedContent([content]);
      return;
    }
    setActiveFolder(content);
    setSelectedContent([]);
    setIsSearching(false);
  };

  return (
    <tr
      className={c(
        'hover:bg-accent-50 hover:cursor-pointer',
        selectedContent.find((content) => content.id === id) && 'bg-accent-100',
      )}
    >
      <td className="w-10 z-10">
        <CheckBox
          data-test={'checkbox-' + name}
          checked={
            selectedContent.find((content) => content.id === id) || false
          }
          onChange={onCheckboxChange}
        />
      </td>

      <td className="w-10" onClick={onRowClick}>
        {isFile && thumbnailSmall && (
          // alt is currently empty because we don't add alt texts to every image (yet)
          <div className="w-5 h-5 overflow-hidden grid place-items-center">
            <img className="w-5 object-cover" src={thumbnailSmall} alt="" />
          </div>
        )}
        {isFile && !thumbnailSmall && <DocumentIcon className="w-5" />}
        {isFile || <FolderIcon className="w-5" />}
      </td>
      <td onClick={onRowClick}>{fileNameWithExtension(content)}</td>
      <td onClick={onRowClick}>
        {!isSearching ? fileDateFormatter(modifiedOn) : path}
      </td>
      <td onClick={onRowClick}>{modifiedBy}</td>
      <td className="flex justify-center">
        {!isFile ? (
          <abbr title={t('file.manager.not-applicable')}>
            {t('file.manager.abbr.not-applicable')}
          </abbr>
        ) : // if there are slides on which the file is used, show the number of slides
        !!slides?.length ? (
          `${slides.length} ${t(
            slides.length === 1 ? 'file.manager.slide' : 'file.manager.slides',
          )}`
        ) : (
          // else show a dash
          '-'
        )}
      </td>
    </tr>
  );
};

export default FolderDetailItem;
