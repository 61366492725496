import { useContext } from 'react';

import { Loader } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { FileManagerContext } from '../FileManagerContextProvider';

import FolderDetailItem from './FolderDetailItem';

const FolderDetail = () => {
  const t = useFormatMessage();
  const {
    currentFolderContent,
    currentFolderContentLoading,
    searchResults,
    isSearching,
    searchLoading,
  } = useContext(FileManagerContext);

  if (currentFolderContentLoading || searchLoading) {
    return (
      <div className="flex justify-center w-full mt-5">
        <div className="w-5">
          <Loader />
        </div>
      </div>
    );
  }

  if (currentFolderContent?.length === 0) {
    return <p className="text-center my-2">{t('file.manager.empty-folder')}</p>;
  }

  if (isSearching && searchResults?.length === 0) {
    return (
      <p className="text-center my-2">{t('file.manager.search-no-results')}</p>
    );
  }
  return (
    <table className="w-full m-2 table-auto">
      <thead className="sticky top-0 bg-white">
        <tr>
          <th>
            <span className="sr-only">select content</span>
          </th>
          <th>
            <span className="sr-only">content icon</span>
          </th>
          <th className="text-start">{t('global.name')}</th>
          <th className="text-start">
            {!isSearching
              ? t('file.manager.modifiedOn')
              : t('file.manager.path')}
          </th>
          <th className="text-start">{t('file.manager.modifiedBy')}</th>
          <th>{t('file.manager.usage')}</th>
        </tr>
      </thead>
      <tbody>
        {(isSearching ? searchResults : currentFolderContent)?.map(
          (content) => (
            <FolderDetailItem key={content.path} content={content} />
          ),
        )}
      </tbody>
    </table>
  );
};

export default FolderDetail;
