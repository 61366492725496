import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  ArrowUpTrayIcon,
  ClipboardDocumentIcon,
  PlusIcon,
  ScissorsIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

import {
  CheckBox,
  ConfirmationDialog,
  FileInput,
  FilledButton,
  Input,
  Loader,
} from '@ftrprf/tailwind-components';

import { useContentDelete } from '../../../hooks/graphql/file-manager/mutations/useContentDelete';
import useCreateFolder from '../../../hooks/graphql/file-manager/mutations/useCreateFolder';
import useFileMove from '../../../hooks/graphql/file-manager/mutations/useFileMove';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { FileManagerContext } from '../FileManagerContextProvider';

const FileManagerButtons = () => {
  const t = useFormatMessage();

  const [dialog, setDialog] = useState();
  const [copiedContent, setCopiedContent] = useState([]);
  const [oldFolderId, setOldFolderId] = useState();
  let newFolderNameRef = useRef();
  const searchRef = useRef();
  const { deleteContent } = useContentDelete();
  const { moveFiles } = useFileMove();
  const { createFolder } = useCreateFolder();

  const {
    activeFolder,
    isSearching,
    currentFolderContent,
    selectedContent,
    refetchCurrentFolderContent,
    setSelectedContent,
    searchContent,
    searchResults,
    setIsSearching,
    reloadFileTree,
    uploadFiles,
    isFileUploadLoading,
  } = useContext(FileManagerContext);

  const onSearch = useCallback(
    (value) => {
      if (!value || value.length < 3) {
        setIsSearching(false);
        return;
      }
      setIsSearching(true);
      searchContent({
        variables: {
          input: {
            name: value,
            folderId: activeFolder?.id || null,
            searchType: 'FILES_AND_FOLDERS',
          },
        },
        fetchPolicy: 'network-only',
      });
    },
    [activeFolder?.id, searchContent, setIsSearching],
  );

  const onCut = useCallback(() => {
    setOldFolderId(activeFolder?.id);
    setCopiedContent(selectedContent);
  }, [activeFolder?.id, selectedContent]);

  const onDelete = useCallback(async () => {
    await deleteContent({
      variables: { contentIds: selectedContent.map((content) => content.id) },
    });
    setSelectedContent([]);
    reloadFileTree();
    setDialog();
    if (isSearching) {
      onSearch(searchRef.current.value);
    } else {
      refetchCurrentFolderContent();
    }
  }, [
    deleteContent,
    isSearching,
    onSearch,
    refetchCurrentFolderContent,
    reloadFileTree,
    selectedContent,
    setSelectedContent,
  ]);

  const onMove = useCallback(async () => {
    await moveFiles({
      variables: {
        contentIds: copiedContent.map((content) => content.id),
        newFolderId: activeFolder?.id || null,
        oldFolderId,
      },
    });
    reloadFileTree();
    refetchCurrentFolderContent();
    setDialog();
  }, [
    activeFolder?.id,
    copiedContent,
    moveFiles,
    oldFolderId,
    refetchCurrentFolderContent,
    reloadFileTree,
  ]);

  const onNewFolder = useCallback(async () => {
    const name = newFolderNameRef.current;
    if (!name) {
      return;
    }
    await createFolder({
      variables: {
        folderName: name,
        parentFolderId: activeFolder?.id || null,
      },
    });
    reloadFileTree();
    refetchCurrentFolderContent();
    setDialog();
  }, [
    activeFolder?.id,
    createFolder,
    refetchCurrentFolderContent,
    reloadFileTree,
  ]);

  useEffect(() => {
    if (isSearching && !activeFolder) {
      onSearch(searchRef.current.value);
    }
  }, [activeFolder, isSearching, onSearch]);

  return (
    <>
      <div className="flex flex-none items-center m-3 ml-2 gap-2 h-5">
        <h2 className="sr-only">{t('file.manager.buttons.title')}</h2>
        <div className="flex h-fit shrink-0">
          <CheckBox
            checked={
              selectedContent?.length &&
              selectedContent?.length === currentFolderContent?.length
            }
            onChange={(value) => {
              if (value) {
                setSelectedContent(
                  isSearching ? searchResults : currentFolderContent,
                );
                return;
              }
              setSelectedContent([]);
            }}
            disabled={!currentFolderContent?.length}
          />
          <label className="ml-2 align-middle h-fit">
            {t('file.manager.select-all')}
          </label>
        </div>

        <FilledButton
          small
          iconBefore={TrashIcon}
          disabled={
            selectedContent.length === 0 ||
            selectedContent.filter((content) => content.slides?.length).length
          }
          onClick={() => setDialog('delete')}
        >
          {t('global.delete')}
        </FilledButton>
        {isSearching || (
          <FilledButton
            small
            iconBefore={ScissorsIcon}
            disabled={selectedContent.length === 0 || copiedContent?.length > 0}
            onClick={onCut}
          >
            {t('file.manager.cut')}
          </FilledButton>
        )}
        <div className="relative flex-grow">
          <Input
            ref={searchRef}
            className="text-xs"
            placeholder={t('file.manager.search-in-folder')}
            onChange={(e) => onSearch(e.target.value)}
          />
          {isSearching && (
            <button
              aria-label={t('file.manager.clear-search')}
              className="absolute h-full top-0 right-3 hover:cursor-pointer"
              onClick={() => {
                searchRef.current.value = '';
                setIsSearching(false);
              }}
            >
              <XMarkIcon className="w-3 text-gray-400" />
            </button>
          )}
        </div>
        {isSearching || (
          <>
            {copiedContent.length > 0 && (
              <FilledButton
                small
                iconBefore={ClipboardDocumentIcon}
                disabled={
                  activeFolder?.id === oldFolderId ||
                  activeFolder === oldFolderId
                }
                onClick={() => setDialog('move')}
              >
                {t('file.manager.paste')}
              </FilledButton>
            )}
            <FilledButton
              small
              className="shrink-0"
              iconBefore={PlusIcon}
              onClick={() => setDialog('newFolder')}
            >
              {t('file.manager.create-folder')}
            </FilledButton>
            {!isFileUploadLoading ? (
              <FileInput
                data-test="file-upload"
                onUpload={uploadFiles}
                multiple={true}
              >
                {(onOpenFileDialog) => (
                  <FilledButton
                    className="shrink-0"
                    iconBefore={ArrowUpTrayIcon}
                    small
                    onClick={onOpenFileDialog}
                  >
                    {t('file.manager.upload')}
                  </FilledButton>
                )}
              </FileInput>
            ) : (
              <div className="h-fit w-5">
                <Loader />
              </div>
            )}
          </>
        )}
      </div>
      {dialog === 'delete' && (
        <ConfirmationDialog
          content={<p>{t('file.manager.delete.confirmation')}</p>}
          onConfirm={onDelete}
          onDismiss={() => setDialog()}
        />
      )}
      {dialog === 'move' && (
        <ConfirmationDialog
          content={<p>{t('file.manager.move-confirmation')}</p>}
          onConfirm={onMove}
          onDismiss={() => setDialog()}
        />
      )}
      {dialog === 'newFolder' && (
        <ConfirmationDialog
          title={t('file.manager.new-folder')}
          confirmText={t('file.manager.new-folder-confirm')}
          content={
            <Input
              onKeyDown={(e) => e.key === 'Enter' && onNewFolder()}
              placeholder={t('file.manager.folder-name')}
              onChange={(e) => {
                newFolderNameRef.current = e.target.value;
              }}
            />
          }
          onConfirm={onNewFolder}
          onDismiss={() => setDialog()}
        />
      )}
    </>
  );
};

export default FileManagerButtons;
