import { useCallback, useEffect, useRef, useState } from 'react';
import CKEditor from 'ckeditor4-react';
import loadScript from 'load-script';

import useCustomColors from './hooks/useCustomColors';
import useCustomMainEditorStyles from './hooks/useCustomMainEditorStyles';
import useCustomSideEditorStyles from './hooks/useCustomSideEditorStyles';
import useInitialize from './hooks/useInitialize';
import { useWindowWidth } from './hooks/useWindowWidth';

import c from './utils/c';

import { toolbarGroups } from './config/config';

import CustomButtons from './CustomButtons';
import DynamicStyles from './DynamicStyles';

const SMALL_CLASSNAME = 'Editor__Small';
const BIG_CLASSNAME = 'Editor__Big';

export const Editor = ({
  id,
  className,
  url,
  value,
  styles,
  onChange,
  children,
  language,
  contentStyles,
  disabled,
  isMainEditor,
}) => {
  const {
    dropDownStyle,
    accentColor,
    secondaryColor,
    fontFamily,
    dialogAccentColor,
  } = styles;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSmall, setIsSmall] = useState();

  const [instanceReady, setInstanceReady] = useState(false);

  const windowWidth = useWindowWidth();
  const customMainEditorStyles = useCustomMainEditorStyles();
  const customSideEditorStyles = useCustomSideEditorStyles();

  const customColors = useCustomColors();

  const editorRef = useRef(null);
  const containerRef = useCallback(
    (node) => {
      if (node) {
        setIsSmall(node.getBoundingClientRect().width < 370);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowWidth],
  );

  const [customButtonsLocation, editorAPI] = useInitialize(
    editorRef,
    contentStyles,
  );

  useEffect(() => {
    if (id && editorAPI && instanceReady) {
      // Small delay because data is not loaded in immedeately
      const timeOut = setTimeout(() => {
        editorAPI.resetUndo();
      }, 200);

      return () => clearTimeout(timeOut);
    }

    return () => {};
  }, [id, editorAPI, instanceReady]);

  useEffect(() => {
    if (!isLoaded) {
      loadScript(url, () => {
        setIsLoaded(true);
      });
    }
  }, [url, isLoaded]);

  const onBeforeLoad = useCallback(
    (CKEDITOR) => {
      CKEDITOR.disableAutoInline = true;
      if (!CKEDITOR.stylesSet?.registered?.mainEditor) {
        CKEDITOR.stylesSet.add('mainEditor', customMainEditorStyles);
        CKEDITOR.stylesSet.add('sideEditor', customSideEditorStyles);
      }

      if (isMainEditor) {
        CKEDITOR.config.stylesSet = 'mainEditor';
      } else {
        CKEDITOR.config.stylesSet = 'sideEditor';
      }

      CKEDITOR.config.colorButton_colorsPerRow = 11;
      CKEDITOR.config.colorButton_colors = customColors;
      CKEDITOR.config.forсePasteAsPlainText = false;
      CKEDITOR.config.pasteFromWordRemoveFontStyles = false;
      CKEDITOR.config.pasteFromWordRemoveStyles = false;
      CKEDITOR.config.allowedContent = true;
      CKEDITOR.config.extraAllowedContent = 'p(mso*,Normal)';
      CKEDITOR.config.pasteFilter = null;
      CKEDITOR.config.copyFormatting_disallowRules = '*{margin*}';
      CKEDITOR.config.disableNativeSpellChecker = false;
    },
    [
      customColors,
      customMainEditorStyles,
      customSideEditorStyles,
      isMainEditor,
    ],
  );

  const onInstanceReady = useCallback(
    (event) => {
      event.editor.setData(value);
      setInstanceReady(true);
      window.CKEDITOR.addCss(contentStyles);
    },
    [value, contentStyles],
  );

  const fixMagicLines = useCallback(() => {
    const items =
      editorRef.current.editor.document.$.querySelectorAll('p > span');
    for (let item of items) {
      const redLines = item?.querySelectorAll('span[style]');
      for (let redLine of redLines) {
        if (redLine?.style?.borderTop && redLine?.style?.zIndex) {
          redLine?.remove();
        }
      }
    }
  }, [editorRef]);

  return isLoaded ? (
    <div
      className={c(
        'Editor',
        className,
        isSmall ? SMALL_CLASSNAME : BIG_CLASSNAME,
      )}
      ref={containerRef}
    >
      <DynamicStyles
        dropDownStyle={dropDownStyle}
        accentColor={accentColor}
        secondaryColor={secondaryColor}
        fontFamily={fontFamily}
        dialogAccentColor={dialogAccentColor}
        isSmallScreen={isSmall}
      />
      <CKEditor
        ref={editorRef}
        className="editor"
        onChange={
          disabled || !instanceReady
            ? undefined
            : (event) => {
                fixMagicLines();
                onChange(event.editor.getData());
              }
        }
        onBeforeLoad={onBeforeLoad}
        onInstanceReady={onInstanceReady}
        readOnly={disabled}
        data={value}
        config={{
          language: language,
          toolbarGroups: toolbarGroups,
        }}
      />
      <CustomButtons
        buttonLocation={customButtonsLocation}
        editorAPI={editorAPI}
      >
        {children}
      </CustomButtons>
    </div>
  ) : null;
};

Editor.defaultProps = {
  url: process.env.REACT_APP_CKEDITOR_URL,
  language: 'en',
  value: '',
  styles: {
    dropDownStyle: {
      borderColor: 'rgba(228,228,231,1)',
      border: 'solid 1px',
      borderRadius: '0.25rem',
      display: 'flex',
      overflow: 'hidden',
    },
    accentColor: '#42526E',
    secondaryColor: '#E4E4E7',
    dialogAccentColor: 'hsla(196, 100%, 60%, 1)',
    fontFamily: 'Inter',
    titleFontSize: '1.25rem',
    borderRadius: '0.5rem',
  },
  onChange: () => {},
  contentStyles: '',
};

export const createInlineStyle = (styleObject) => {
  const style = new window.CKEDITOR.style(styleObject);
  style.type = window.CKEDITOR.STYLE_INLINE;
  return style;
};

export const hideDialog = () => {
  if (window.CKEDITOR.dialog.getCurrent()) {
    window.CKEDITOR.dialog.getCurrent().hide();
  }
};
