import { useContext } from 'react';

import DragArea from '../../components/DragArea';

import FileManagerTree from './partials/FileManagerTree';
import FolderView from './partials/FolderView';
import { FileManagerContext } from './FileManagerContextProvider';

const FileManager = () => {
  const { activePath, fileTreeKey, uploadFiles, isFileUploadLoading } =
    useContext(FileManagerContext);

  return (
    <div className="flex w-full h-full grow px-5 pt-5 divide-x-4 divide-black overflow-hidden">
      <DragArea uploadFiles={uploadFiles} isUploading={isFileUploadLoading}>
        {activePath && <FileManagerTree key={fileTreeKey} />}
        <FolderView />
      </DragArea>
    </div>
  );
};

export default FileManager;
