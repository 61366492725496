import { domains } from '../utils/constants/domains';

import { externalNl } from './external';
import { generate } from './generate';

export default {
  // Globals
  'global.add': 'Voeg toe',
  'global.edit': 'Bewerk',
  'global.copy': 'Kopieer',
  'global.copied': 'Gekopieerd',
  'global.cancel': 'Annuleer',
  'global.close': 'Sluiten',
  'global.delete': 'Verwijder',
  'global.confirm': 'Bevestig',
  'global.save': 'Opslaan',
  'global.saving': 'Opslaan...',
  'global.saved': 'Opgeslagen',
  'global.rename': 'Naam wijzigen',
  'global.name': 'Naam',
  'global.folder': 'Map',
  'global.on': 'op',
  'global.empty': 'Het lijkt erop dat er hier niets is!',
  'global.username': 'Gebruikersnaam',
  'global.answer': 'Antwoord',
  'global.question': 'Vraag',
  'global.go_back': 'Ga terug',
  'global.no_results': 'Geen resultaten gevonden',
  'global.try-again': 'Probeer opnieuw',
  'global.not_required': 'Niet verplicht',
  'global.URL': 'URL',
  'global.archive': 'Archiveer',
  'global.unarchive': 'Dearchiveer',
  'global.view': 'Bekijk',
  'global.search': 'Zoek',
  'global.upload': 'Upload',
  'global.download': 'Download',
  'global.testplan': 'Testplan',
  'global.duplicate': 'Dupliceer',
  'global.height': 'Hoogte',
  'global.width': 'Breedte',

  // Languages
  'languages.nl': 'Nederlands',
  'languages.en': 'English',

  // Sign in page
  'login.username': 'Gebruikersnaam',
  'login.password': 'Wachtwoord',
  'login.login': 'Aanmelden',
  'login.title': 'Studio',
  'login.forgot_password': 'Gebruikersnaam/wachtwoord vergeten?',
  [`login.welcome_message.${domains.CODEFEVER}`]:
    'Welkom bij de CodeFever Studio! Hier maak je lescontent en oefeningen.',
  [`login.welcome_message.${domains.FTRPRF}`]:
    'Welkom bij de FTRPRF Studio! Hier maak je lescontent en oefeningen.',
  [`login.switch_to.${domains.CODEFEVER}`]: 'Ik ben een CodeFever gebruiker',
  [`login.switch_to.${domains.FTRPRF}`]: 'Ik ben een FTRPRF gebruiker',
  'login.or': 'of',

  // Header
  'header.dashboard': 'Dashboard',
  'header.lessons': 'Leer',
  'header.exams': 'Test',
  'header.exercises': 'Codeer',
  'header.templates': 'Sjablonen',
  'header.files': 'Mijn bestanden',
  'header.admin': 'Admin',
  'header.hello': 'Hallo, ',
  'header.sign_out': 'Uitloggen',
  'header.usermenu': 'Gebruikersmenu',
  'header.navmenu': 'Navigatiemenu',
  'header.close_menu': 'Menu sluiten',
  'header.back_to_FTRPRF': 'Terug naar FTRPRF',

  // viewModes
  'view-modes.teacher_manual': 'Handleiding leraar',
  'view-modes.projection': 'Projectie',
  'view-modes.selfstudy': 'Zelfstudie',
  'view-modes.classical': 'Klassikaal',

  // contentStates
  'content-state.CONCEPT': 'Concept',
  'content-state.PUBLISHED': 'Gepubliceerd',
  'content-state.ARCHIVED': 'Gearchiveerd',

  // content-overview
  'content-overview.toggle_filter': 'Filter',
  'content-overview.filters.title': 'Titel',
  'content-overview.filters.programme': 'Programma',
  'content-overview.filters.clan': 'Clan',
  'content-overview.filters.level': 'Level',
  'content-overview.filters.lesson': 'Les',
  'content-overview.filters.version': 'Versie',
  'content-overview.filters.state': 'Status',
  'content-overview.filters.tags': 'Tags',

  ...generate(
    [
      {
        key: 'EXAM',
        name: 'test',
        plural: 'testen',
        new: 'nieuw',
        article: 'Het',
      },
      {
        key: 'LESSON',
        name: 'les',
        plural: 'lessen',
        new: 'nieuwe',
        article: 'De',
      },
      {
        key: 'LESSON_TEMPLATE',
        name: 'sjabloon',
        plural: 'sjablonen',
        new: 'nieuwe',
        article: 'Het',
      },
    ],
    {
      'content-overview.{{ key }}.title': '{{ plural | ucase }}',
      'content-overview.{{ key }}.new_content': '{{ new | ucase }} {{ name }}',
      'content-overview.{{ key }}.empty': 'Er zijn nog geen {{ plural }}',
      'content-overview.{{ key }}.copy.success':
        '{{ article }} {{ name }} is succesvol gekopieerd.',
      'content-overview.{{ key }}.new-content.success':
        'Een {{ new }} {{ name }} is succesvol toegevoegd.',
      'content-overview.{{ key }}.publish.success':
        '{{ article }} {{ name }} is succesvol gepubliceerd.',
      'content-overview.{{ key }}.archive.success':
        '{{ article }} {{ name }} wordt gearchiveerd. Dit kan enkele seconden duren.',
      'content-overview.{{ key }}.unarchive.success':
        '{{ article }} {{ name }} wordt gedearchiveerd. Dit kan enkele seconden duren.',
      'content-overview.{{ key }}.remove.success':
        '{{ article }} {{ name }} is succesvol verwijderd.',
      'content-overview.{{ key }}.meta.success':
        '{{ article }} metadata van {{ article | lowercase }} {{ name }} is gewijzigd.',
      'content-meta-data-dialog.{{ key }}.new_title': 'Nieuwe {{ name }}',
      'content-meta-data-dialog.{{ key }}.update_title': 'Bewerk {{ name }}',
    },
  ),

  'content-overview.new-version.success':
    'Een nieuwe versie is succesvol aangemaakt.',
  'content-editor.warning.delete': 'Een les moet minimaal 1 slide bevatten',

  // content meta data dialog
  

  // Lesson Card
  'content-card.image_alt': 'Afbeelding voor de les',

  // Lesson Content
  'content.title': 'Titel',
  'content.type': 'Type',
  'content.language': 'Taal',
  'content.tags': 'Tags',
  'content.endGoals': 'Eindtermen',
  'content.filter':'Enkel letters en cijfers zijn toegestaan',
  'content.image': 'Afbeelding',
  'content.clan': 'Clan',
  'content.level': 'Level',
  'content.version': 'Version',
  'content.program': 'Programma',
  'content.last_modified_by': 'Laatst aangepast door',
  'content.last_modified_on': 'Laatst aangepast op',
  'content.groupRoles': 'Groep Rollen',
  'content.description': 'Beschrijving',
  'content.summary': 'Samenvatting',
  'content.template': 'Kies een sjabloon of werkvorm',
  'content.template.view': 'bekijk sjabloon',
  'content.group_lesson': 'Groeples',

  // Slide overview
  'slide-thumbnail.contains-question': 'Slide bevat vraag',

  // Studio
  'content-editor.lesson_title.placeholder': 'Lestitel...',
  'content-editor.title.placeholder': 'Slidetitel...',
  'content-editor.add_slide': 'Slide',
  'content-editor.add_activity': 'Activiteit',
  'content-editor.return_to_editor':'Terug naar editor',
  'link-plugin.url.dialog': 'URL Dialog',
  'link-plugin.url.add-url': 'Voeg URL toe',
  'link-plugin.url.modify-url': 'Pas URL aan',
  'link-plugin.url.add-exercise': 'Voeg oefening toe',
  'link-plugin.url.modify-exercise': 'Pas oefening aan',
  'link-plugin.url.link-style.label': 'Linkstijl',
  'link-plugin.url.warning': 'Deze slide en de slide waarnaar je verwijst moeten exact dezelfde viewmodes hebben én in dezelfde les zitten. Anders kunnen errors voorkomen bij het openen van de link.',
  'link-plugin.file_name': 'Bestands naam',
  'link-plugin.is_download': 'Is een download link',
  'link-plugin.open_new_tab': 'Open link in nieuw tabblad',
  'link-plugin.display-text': 'Weergavetekst',
  'link-plugin.link-style.ExerciseLink': 'Oefening',
  'link-plugin.link-style.OtherLink': 'Andere',
  'link-plugin.link-style.SlideLink': 'Slide',
  'link-plugin.link-style.VideoLink': 'Video',
  'link-plugin.link-style.ExternalLink': 'Extern',
  'link-plugin.link-style.DownloadLink': 'Download',
  'link-plugin.link-style.SteamsQR': 'QR',
  'exercise-plugin.url.exercise-default': 'Open oefening',

  'content-editor.header.last_edit_was': 'Laatste bewerking was',
  'content-editor.header.preview': 'Bekijk',
  'content-editor.header.metadata': 'Metadata',
  'content-editor.header.actions': 'Acties',
  'content-editor.header.actions.archive': 'Archiveer',
  'content-editor.header.actions.save': 'Opslaan',
  'content-editor.header.publish': 'Publiceer',
  'content-editor.header.new_version': 'Nieuwe versie',
  'content-editor.header.settings': 'Instellingen',
  'content-editor.header.copy': 'Kopieer',
  'content-editor.header.archive': 'Archiveer',
  'content-editor.new_version_confirm':
    'Ben je zeker dat je een nieuwe versie wilt maken?',

  ...generate(
    [
      { key: 'EXAM', name: 'examen', article: 'het' },
      { key: 'LESSON_TEMPLATE', name: 'sjabloon', article: 'het' },
      { key: 'LESSON', name: 'les', article: 'de' },
    ],
    {
      'content-editor.{{ key }}.publish-confirm.title': 'Publiceer {{ name }}',
      'content-editor.{{ key }}.publish-confirm.text1':
        'Ben je zeker dat je {{ article }} {{ name }} wil publiceren?',
      'content-editor.{{ key }}.publish-confirm.text2': `Je zal geen veranderingen meer kunnen aanbrengen tenzij je een nieuwe versie maakt.`,
    },
  ),

  'content-editor.errors.attempting_to_delete_all':
    'Je probeert alle slides te verwijderen maar een les moet ten minste 1 slide bevatten.',

  'content-editor.context_menu.insert_slide': 'Slide invoegen',
  'content-editor.context_menu.move_up': 'Naar boven',
  'content-editor.context_menu.move_down': 'Naar beneden',
  'content-editor.buttons.hints': 'Hints',
  'content-editor.buttons.add_hint': 'Nieuwe hint',
  'content-editor.buttons.exercise': 'Oefening',
  'content-editor.buttons.teacher_info': 'Leraar info',
  'content-editor.buttons.add_question': 'Voeg een vraag toe',
  'content-editor.buttons.flow_diagram': 'Steams schema',
  'content-editor.slide.modify_question': 'Pas vraag aan',
  'content-editor.slide.modify-content': 'Pas inhoud van de slide aan',
  'content-editor.no_viewmodes.error':
    'Er moet tenminste 1 view modus geselecteerd zijn per slide',
  'content-editor.question.multiple-choice.add-answer': 'Nieuw antwoord',

  'content-editor.overview.projection_warning':
    'Klassikale slides zijn altijd zichtbaar in de projectieweergave',

  'content-editor.question.open-question.info':
    'Dit is de plek waar studenten hun antwoord op de open vraag zullen invullen.',
  'content-editor.change_question.confirm':
    'Wanneer je het slidetype wijzigt worden de antwoorden verwijderd.',

  'content-editor.styles.default': 'Standaard',
  'content-editor.styles.intertitle': 'Tussentitel',
  'content-editor.styles.subtitle': 'Ondertitel',
  'content-editor.styles.question': 'Vraag',
  'content-editor.styles.text': 'Tekst',
  'content-editor.styles.definition': 'Definitie',
  'content-editor.styles.tip': 'Tip',
  'content-editor.styles.bron': 'Bron',

  'content-editor.colors.scratch.beweging': 'Beweging',
  'content-editor.colors.scratch.uiterlijken': 'Uiterlijken',
  'content-editor.colors.scratch.geluid': 'Geluid',
  'content-editor.colors.scratch.gebeurtenis': 'Gebeurtenis',
  'content-editor.colors.scratch.besturen': 'Besturen',
  'content-editor.colors.scratch.waarnemen': 'Waarnemen',
  'content-editor.colors.scratch.functies': 'Functies',
  'content-editor.colors.scratch.variabelen': 'Variabelen',
  'content-editor.colors.scratch.mijn_blokken': 'Mijn blokken',
  'content-editor.colors.scratch.pen_makey': 'Pen en Maykeymakey',

  'content-editor.colors.minecraft.basic': 'Basis',
  'content-editor.colors.minecraft.player': 'Speler',
  'content-editor.colors.minecraft.blocks': 'Blokken',
  'content-editor.colors.minecraft.mobs': 'Mobs',
  'content-editor.colors.minecraft.agent': 'Agent',
  'content-editor.colors.minecraft.gameplay': 'Gameplay',
  'content-editor.colors.minecraft.positions': 'Postities',
  'content-editor.colors.minecraft.loops': 'Lussen',
  'content-editor.colors.minecraft.logic': 'Logisch',
  'content-editor.colors.minecraft.variables': 'Variabelen',
  'content-editor.colors.minecraft.math': 'Rekenen',
  'content-editor.colors.minecraft.functions': 'Functies',
  'content-editor.colors.minecraft.arrays': 'Matrices',
  'content-editor.colors.minecraft.text': 'Tekst',
  'content-editor.colors.minecraft.builder': 'Bouwer',
  'content-editor.colors.minecraft.shapes': 'Vormen',

  'content-editor.colors.white': 'Wit',
  'content-editor.colors.subtitle-defintion.color': 'Ondertitel en defintie',

  'content-editor.activity.title': 'Activiteit',
  'content-editor.activity.type':'Type activiteit',
  'content-editor.activity.type_placeholder':'Zoek of creëer activiteit',
  'content-editor.activity.image_add':'Icoon toevoegen',
  'content-editor.activity.image_change':'Icoon wijzigen',
  'content-editor.activity.method':'Publiek',
  'content-editor.activity.image.select':'Selecteer afbeelding',

  // SlideType
  'slide_type.TEXT': 'Tekstslide',
  'slide_type.MULTIPLE_CHOICE': 'Meerkeuzevraag',
  'slide_type.OPEN': 'Open vraag',

 // SlideMethod
 'slide_method.CLASS': 'Klassikaal',
 'slide_method.INDIVIDUALLY': 'Individueel',
 'slide_method.GROUP': 'Groep',

  // SlidePart
  'slide_part.INTRODUCTION': 'Inleiding',
  'slide_part.EXERCISE': 'Opdracht uitvoeren',
  'slide_part.THEORY': 'Theorie',
  'slide_part.CLOSURE': 'Slot',

  // Admin
  'admin.logo': 'Logo',
  'admin.name': 'Naam',
  'admin.firstname': 'Voornaam',
  'admin.lastname': 'Achternaam',
  'admin.stats': 'Statistieken',
  'admin.users': 'Gebruikers',
  'admin.description': 'Beschrijving',
  'admin.organizations': 'Organisaties',
  'admin.organizations.filter': 'Welke organisatie zoek je?',
  'admin.groups': 'Groepen',
  'admin.groups.filter': 'Welke groep zoek je?',
  'admin.groups.add-group': 'Voeg groep toe',
  'admin.groups.add-group.success': 'Groep toegevoegd!',
  'admin.groups.edit-group': 'Pas groep aan',
  'admin.groups.edit-group.success': 'Groep aangepast!',
  'admin.groups.remove-group.success': 'Groep verwijderd!',

  // Multiselect list
  available: 'Beschikbaar',
  added: 'Toegevoegd',
  'looking-for': 'Wie zoek je?',

  // Filterbar
  'filterbar.clear': 'Verwijder filters',
  'filterbar.show': 'Toon filters',
  'filterbar.hide': 'Verberg filters',

  // Dropdown
  'dropdown.no_results': 'Geen resultaten',

  // Slide viewer
  'slide_viewer.teacher_manual': 'Handleiding leerkracht',
  'slide_viewer.teacher_manual_empty':
    'Er is geen extra uitleg nodig bij deze slide',

  // Content selector
  'content-selector.title.default': 'Mijn bestanden',
  'content-selector.add': 'Sleep bestanden hier',
  'content-selector.empty_folder': 'Deze map is leeg',
  'content-selector.drop_items': 'Plaats bestanden hier',
  'content-selector.url': 'Voeg url in',
  'content-selector.image': 'Voeg afbeelding toe',
  'content-selector.add_external': 'Voeg externe bron toe',

  // Confirmation dialog
  'confirmation_dialog.title': 'Weet je het zeker?',

  // Content Manager
  'content-manager.title': 'Mijn bestanden',

  // Errors
  'errors.label': 'Error',
  'errors.something_went_wrong':
    'Er is iets fout gegaan. Gelieve opnieuw te proberen.',
  'errors.user_does_not_exist':
    'De gebruikersnaam of het wachtwoord is incorrect.',
  'errors.network_error':
    'We hebben moeite om de server te bereiken... Gelieve opnieuw te proberen.',
  'errors.session_expired':
    'Uw sessie is verlopen. Gelieve opnieuw in te loggen.',

  // 404 Page
  'errorpage.title': '404',
  'errorpage.header': 'Oeps!',
  'errorpage.subheader': 'Net zoals eenhoorns, bestaat deze pagina niet.',
  'errorpage.message':
    'Ze is mogelijk verwijderd, van URL veranderd, of tijdelijk niet beschikbaar.',
  'errorpage.to_home': 'Terug naar de homepagina',
  'errorpage.refresh': 'Of probeer opnieuw',

  // Content Manager actions
  'content-manager.actions.create_folder': 'Nieuwe Map',
  'content-manager.actions.create_file': 'Upload Bestand',
  'content-manager.actions.rename': 'Naam wijzigen',
  'content-manager.actions.copy-link': 'Kopieer link',
  'content-manager.actions.rename-title': 'Naam wijzigen',
  'content-manager.actions.rename-title.success': 'De naam is gewijzigd',
  'content-manager.actions.overwrite': 'Bestand Overschrijven',
  'content-manager.actions.create': 'Maak',
  'content-manager.actions.cut': 'Knippen',
  'content-manager.actions.copy': 'Kopieren',
  'content-manager.actions.paste': 'Plakken',
  'content-manager.actions.delete': 'Verwijderen',
  'content-manager.actions.delete.pay_attention': 'Let op!',
  'content-manager.actions.delete.confirm_text':
    'Als je dit bestand verwijdert zal het niet meer beschikbaar zijn in de lessen.',
  'content-manager.actions.trashcan': 'Prullenbak',
  'content-manager.actions.undo': 'Vorige',
  'content-manager.actions.redo': 'Volgende',
  'content-manager.actions.move_to': 'Verplaats naar',
  'content-manager.actions.copy_to': 'Kopieer naar',
  'content-manager.actions.create-file.success': 'Het bestand is aangemaakt.',
  'content-manager.actions.create-file.file-too-big':
    'Bestand is te groot om te uploaden. Kies een kleiner bestand alstublieft.',
  'content-manager.actions.create-folder.success': 'De map is aangemaakt.',
  'content-manager.actions.remove-file.success': 'Het bestand is verwijderd.',
  'files.actions-context_menu.copy_here': 'Kopieer',
  'files.actions-context_menu.copy_file_to': 'Kopieer bestand naar',
  'files.actions-context_menu.move_here': 'Plaats hier',
  'files.actions-context_menu.move_file_to': 'Verplaats bestand',
  'files.drop_file': 'Sleep bestanden hier om te beginnen',
  'content-manager.actions.rename.path-exists':
    'Deze naam is reeds gebruikt in deze map. Wil je toch uploaden?',
  'content-manager.actions.move-file.success': 'Bestand verplaatst',
  'content-manager.actions.paste-file.success': 'Het bestand is toegevoegd.',
  'content-manager.actions.error.only-image':
    'Enkel afbeeldingen zijn toegelaten',
  'content-manager.actions.error.only-video': "Enkel video's zijn toegelaten",

  // Content Manager preview
  'content-manager.preview.path': 'Bestandsnaam',
  'content-manager.preview.modifiedOn': 'Laatst gewijzigd op',
  'content-manager.preview.modifiedBy': 'Laatst gewijzigd door',
  'content-manager.preview.extension': 'Extensie',
  'content-manager.preview.createdOn': 'Aangemaakt op',
  'content-manager.preview.size': 'Grootte',
  'content-manager.preview.createdBy': 'Aangemaakt door',
  'content-manager.preview.mutiple_files': 'Selectie',

  // Editor
  'editor.imageplugin.label': 'Afbeelding toevoegen',
  'editor.videoplugin.label': 'Video toevoegen',
  'editor.modalplugin.label': 'Popup toevoegen',
  'editor.linkplugin.label': 'Link toevoegen',
  'editor.audioplugin.label': 'Audio toevoegen',
  'editor.exerciseplugin.label': 'Oefening toevoegen',
  'editor.iframeplugin.label': 'Iframe toevoegen',
  'editor.iframeplugin.error.iframe.size': 'Dit veld kan niet groter zijn dan 1500 px',
  'editor.imageplugin.label-external': 'Externe afbeelding toevoegen',
  'editor.videoplugin.label-external': 'Externe video toevoegen',
  'editor.imageplugin.label-upload': 'Of upload een afbeelding',
  'editor.videoplugin.label-upload': 'Of upload een video',
  'editor.editmodalsidebar.title': 'Modal knop',
  'editor.editmodalsidebar.label-buttontext': 'Weergavetekst',
  'editor.editmodalsidebar.label-text': 'Tekst',
  'editor.editmodalsidebar.label-modaltitle': 'Titel modal',
  'editor.editmodalsidebar.label-modalsize': 'Grootte modal',
  'editor.editmodalsidebar.label-content': 'Inhoud',
  'editor.editmodalsidebar.label-title': 'Titel',
  'editor.audioplugin.label-upload': 'Of upload een audiofragment',

  // Dragarea
  'dragarea.uploading': 'Uploaden van',
  'dragarea.files': 'bestanden',
  'dragarea.file': 'bestand',

  // Formik
  'validation.required': 'Dit veld is vereist',
  'validation.number': 'Dit veld moet een getal bevatten',
  'validation.url': 'Dit veld moet een geldige URL bevatten',
  'validation.external_video_url':
    'Deze URL bevat geen Youtube, Vimeo of Dailymotion video',
  'validation.external_audio_url': 'Deze URL bevat geen audiofragment',
  'validation.external_image_url': 'This URL does not seem to contain an image[En]',
  'validation.empty': 'Dit veld mag niet leeg zijn',

  // SlideSideBar
  'slide-sidebar.slide_title': 'Slidetitel',
  'slide-sidebar.slide_type': 'Slidetype',
  'slide-sidebar.copy_slide_link': 'Kopieer link naar slide',
  'slide-sidebar.link_copied': 'Link gekopieerd!',
  'slide-sidebar.general': 'Algemeen',
  'slide-sidebar.files': 'Mijn bestanden',
  'slide-sidebar.steams': 'STEAMS',
  
  // Steams
  'slide-sidebar-steams.chapter': 'Hoofdstuk',
  'slide-sidebar-steams.public': 'Kies publiek',
  'slide-sidebar-steams.activity': 'Type activiteit',
  'slide-sidebar-steams.time': 'Tijdsbesteding',
  'slide-sidebar-steams.time_text': 'Voeg aftelklok toe',
  'slide-sidebar-steams.visibility': 'Zichtbaarheid',
  'slide-sidebar-steams.visibility_text': 'Maak deze slide enkel zichtbaar voor bepaalde rollen',
  'slide-sidebar-steams.visibility_choose': 'Kies rollen',
  'slide-sidebar-steams.general': 'Algemeen',
  'slide-sidebar-steams.motivation': 'Motivatie',
  'slide-sidebar-steams.motivation.placeholder': 'Motivatie...',

  // QuestionAnswerMultipleChoiceSideBar
  'question-answer-multiple-choice-sidebar.is_correct_answer':
    'Correct antwoord?',
  'question-answer-multiple-choice-sidebar.answer': 'Antwoord',
  'question-answer-multiple-choice-sidebar.explanation': 'Uitleg',
  'question-answer-multiple-choice-sidebar.warning.title':
    'Opgelet, deze vraag is reeds gepubliceerd',
  'question-answer-multiple-choice-sidebar.warning.content':
    'Leerlingen hebben mogelijks deze vraag al beantwoord. Hierdoor kan je enkel kleine wijzigingen aanbrengen zoals typefouten verbeteren. Indien je toch grotere aanpassingen wenst te doen moet je de slide dupliceren.',
  'question-answer-multiple-choice-sidebar.warning.duplicate':
    'Slide dupliceren',
  'question-answer-multiple-choice-sidebar.warning.small': 'Kleine aanpassing',

  // HintsSideBar
  'hints-sidebar.title': 'Hints',
  'hints-sidebar.item.hint_x': 'Hint',
  'hints-sidebar.item.title': 'Titel',
  'hints-sidebar.item.content': 'Inhoud',
  'hints-sidebar.add_hint': 'Nieuwe hint',

  // TeacherInfoSideBar
  'teacher-info-sidebar.title': 'Informatie voor de leraar',
  'teacher-info-sidebar.description':
    'Hier kunnen notities geplaatst worden voor de leraar. Deze info wordt nooit gedeeld met leerlingen.',

  // MultipleChoiceQuestionSideBar
  'multiple-choice-question-sidebar.score_per_correct_answer':
    'Aantal punten per correct antwoord',
  'multiple-choice-question-sidebar.subtract_per_wrong_answer':
    'Aantal minpunten per fout antwoord',
  'multiple-choice-question-sidebar.max_selectable_answers':
    'Max. keuzemogelijkheden',
  'multiple-choice-question-sidebar.shuffle_answers':
    'Antwoorden door elkaar halen?',
  'multiple-choice-question-sidebar.should_find_all_correct':
    'Student moet ze allemaal vinden',

  // OpenQuestionSideBar
  'open-question-sidebar.points': 'Totaal punten',
  'open-question-sidebar.example_solution': 'Voorbeeldoplossing',
  'open-question-sidebar.placeholder': 'Placeholder',
  'open-question-sidebar.show_the_answer': 'Toon het antwoord aan de leerling nadat hij heeft geantwoord',
  'open-question-sidebar.show_the_answer_no': 'Nee',
  'open-question-sidebar.show_the_answer_yes': 'Ja',

  // LastSavedAt
  'last-saved-at.a_few_seconds_ago': 'een paar seconden geleden',

  // ExerciseOverview
  'exercise-overview.title': 'Oefeningen',
  'exercise-overview.new_exercise': 'Nieuwe oefening',
  'exercise-overview.empty': 'Er zijn nog geen oefeningen',
  'exercise-overview.empty_page': 'Er zijn geen oefeningen op deze pagina',
  'exercise-overview.empty_page_action': 'Ga naar de eerste pagina',
  'exercise-overview.delete_success': 'De oefening werd verwijderd',
  'exercise-overview.delete_fail': 'Fout bij verwijderen van oefening',
  'exercise-overview.duplicate-success':'De oefening werd succesvol gedupliceerd',
  'exercise-overview.duplicate-error':'Fout bij het dupliceren van de oefening',

  // ExerciseCreateDialog
  'exercise-create-dialog.title': 'Nieuwe oefening',
  'exercise-create-dialog.label.title': 'Titel',
  'exercise-version-create-dialog.title': 'Voeg versie toe',
  'exercise-version-create-dialog.submit': 'Voeg toe',
  'exercise-version-update-dialog.title': 'Wijzig versie',
  'exercise-version-update-dialog.submit': 'Wijzig',
  'exercise-version-dialog.name': 'Naam',
  'exercise-version-dialog.versionType': 'Versie type',
  'scratch.version.STARTER': 'Starter',
  'scratch.version.SOLUTION': 'Oplossing',
  'scratch.version.DEMO': 'Demo',
  'scratch.version.EXTRA': 'Extra',
  'scratch.version.OTHER': 'Andere',

  // Exercise
  'exercise.error': 'Fout bij het laden van je oefening',
  'exercise.forbidden_error': 'Je hebt geen toegang tot deze oefening',

  // Scratch
  'scratch.header.upload-exercise': 'Upload oefening',
  'scratch.header.turbo': 'Turbo',
  'scratch.upload-test-plan.success': 'Het testplan is aangemaakt',
  'scratch.upload-test-plan.error': 'Er liep iets mis bij het uploaden van het testplan',
  'scratch.test-plan.error': 'Er is geen testplan gelinkt aan deze oefening',
  'scratch.confirmation.content': 'Ben je zeker dat je de oefening wilt herstarten?',

  // Scratch gui
  'gui.ftrprf.testresult.test': 'Test',
  'gui.ftrprf.testresult.tests': 'Testen',
  'gui.ftrprf.testresult.show_subtests': 'Toon alle deeltesten',
  'gui.ftrprf.testresult.hide_subtests': 'Verberg alle deeltesten',
  'gui.ftrprf.testresult.click_to_test': 'Test je code!',
  'gui.ftrprf.testtab': 'Controleren (beta)',
  'gui.ftrprf.testtab.loading': 'Aan het controleren...',
  'gui.ftrprf.translationtab.translation_code': 'Code vertaling',
  'gui.ftrprf.translationtab.translate': 'Vertaal',
  'gui.ftrprf.translationtab.chosen_language': 'Gekozen taal',
  'gui.ftrprf.translationtab.add': 'Toevoegen',
  'gui.ftrprf.translationtab.edit': 'Bewerken',
  'gui.ftrprf.translationtab.add_translation': 'Nieuwe vertaling toevoegen',
  'gui.ftrprf.translationtab.edit_translation': 'Bewerk vertaling',
  'gui.ftrprf.translationtab.dictionary': 'Woordenboek',
  'gui.ftrprf.translationtab.translations': 'Vertalingen',
  'gui.ftrprf.translationtab.groupname': 'Groepnaam',

  //File Manager
  'file.manager.abbr.not-applicable': 'N.V.T.',
  'file.manager.buttons.title': 'Folder en file acties',
  'file.manager.clear-search': 'Clear search',
  'file.manager.content-moved': 'Inhoud verplaatsen geslaagd',
  'file.manager.content-renamed': 'Naam wijzigen geslaagd',
  'file.manager.copy-link': 'Kopieer link',
  'file.manager.create-folder': 'Nieuwe map',
  'file.manager.created-on': 'Aangemaakt op',
  'file.manager.creator': 'Auteur',
  'file.manager.currently-searching-in': 'Aan het zoeken in',
  'file.manager.cut': 'Knippen',
  'file.manager.delete.confirmation': 'Ben je zeker dat je deze inhoud wil verwijderen?',
  'file.manager.delete.failure': 'Er is iets fout gegaan tijdens het verwijderen.',
  'file.manager.delete.success': 'Inhoud verwijderd',
  'file.manager.delete.success.partial': 'Alle ongebruikte bestanden en/of folders zijn verwijderd.  Sommige bestanden worden nog gebruikt en zijn niet verwijderd.  Zorg ervoor dat ze niet meer gebruikt worden, pas dan kan u ze verwijderen.',
  'file.manager.empty-folder': 'Lege map',
  'file.manager.file-detail': 'File informatie',
  'file.manager.file-size': 'Bestandsgrootte',
  'file.manager.files-uploaded': 'Bestand(en) geüpload',
  'file.manager.folder-name': 'Naam map',
  'file.manager.folderview.title': 'Folder detail',
  'file.manager.go-to-folder': 'Bekijk in bovenliggende map',
  'file.manager.last-modified-by': 'Laatst bewerkt door',
  'file.manager.last-modified-on': 'Laatst bewerkt op',
  'file.manager.modifiedOn': 'Bewerkingsdatum',
  'file.manager.modifiedBy': 'Bewerkt door',
  'file.manager.move-confirmation': 'Ben je zeker dat je deze inhoud wil verplaatsen?',
  'file.manager.new-folder': 'Nieuwe map',
  'file.manager.new-folder-confirm': 'Aanmaken',
  'file.manager.not-applicable': 'Niet van toepassing',
  'file.manager.not-used': 'Niet gebruikt',
  'file.manager.not-used-lesson':"Dit bestand wordt niet gebruikt in een les.",
  'file.manager.paste': 'Plak',
  'file.manager.path': 'Pad',
  'file.manager.search-in-folder': 'Zoek in huidige map',
  'file.manager.search-in-root': 'zoek in "mijn bestanden"',
  'file.manager.search-no-results': 'Niets gevonden',
  'file.manager.select-all': 'Selecteer alles',
  'file.manager.slide': 'Slide',
  'file.manager.slides': 'Slides',
  'file.manager.tree.title': 'Bestandsboom',
  'file.manager.upload': 'Bestanden uploaden',
  'file.manager.usage': 'Gebruik',
  'file.manager.use-old-file-manager':'Gebruik de nieuwe file manager',
  'file.manager.use-new-file-manager':'Gebruik de oude file manager',

  // External
  ...externalNl,
};