import { memo, useContext, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Content,
  Loader,
  PageHeader,
  PageTitle,
} from '@ftrprf/tailwind-components';

import BreadCrumbs from '../../components/BreadCrumbs';
import SideBarBreadCrumbs from '../../components/SideBarBreadCrumbs';

import { ContentActionContext } from '../../providers/ContentActionProvider';

import useDebounce from '../../hooks/useDebounce';
import useFormatMessage from '../../hooks/useFormatMessage';
import useTitle from '../../hooks/useTitle';

import {
  FILE_NAME_SORT_ASCENDING,
  FILE_NAME_SORT_DESCENDING,
  LAST_MODIFIED_BY_SORT_ASCENDING,
  LAST_MODIFIED_BY_SORT_DESCENDING,
  LAST_MODIFIED_ON_SORT_ASCENDING,
  LAST_MODIFIED_ON_SORT_DESCENDING,
} from '../../utils/constants/tableSort';
import * as URLS from '../../utils/constants/urls';
import c from '../../utils/functions/c';
import defaultSort from '../../utils/functions/sortByFolderAlphabet';
import {
  fileNameDescendingSort,
  lastModifiedByAscendingSort,
  lastModifiedByDescendingSort,
  lastModifiedOnAscendingSort,
  lastModifiedOnDescendingSort,
} from '../../utils/functions/tableSort';
import mapContent from './utils/functions/mapContent';

import ContentActionBar from './partials/ContentActionBar';
import ContentPreview from './partials/ContentPreview';
import ContentTree from './partials/ContentTree';

const ContentManager = ({
  isHovered,
  isDragging,
  setIsDragging,
  queryPath,
  setQueryPath,
  root,
  files,
  loading,
  setLoading,
  hasPaddingBottom = true,
}) => {
  const t = useFormatMessage();
  const [fileTree, setFileTree] = useState({});
  const debouncedFiles = useDebounce(files, 1000);

  const isSideBarManager = !!queryPath;

  useLayoutEffect(() => {
    if (!isDragging && debouncedFiles) {
      mapContent(defaultSort(debouncedFiles)).then(setFileTree);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFiles]);

  const sortTable = (type) => {
    switch (type) {
      case FILE_NAME_SORT_ASCENDING:
        mapContent(defaultSort(debouncedFiles)).then(setFileTree);
        break;
      case FILE_NAME_SORT_DESCENDING:
        mapContent(fileNameDescendingSort(debouncedFiles)).then(setFileTree);
        break;
      case LAST_MODIFIED_ON_SORT_ASCENDING:
        mapContent(lastModifiedOnAscendingSort(debouncedFiles)).then(
          setFileTree,
        );
        break;
      case LAST_MODIFIED_ON_SORT_DESCENDING:
        mapContent(lastModifiedOnDescendingSort(debouncedFiles)).then(
          setFileTree,
        );
        break;
      case LAST_MODIFIED_BY_SORT_ASCENDING:
        mapContent(lastModifiedByAscendingSort(debouncedFiles)).then(
          setFileTree,
        );
        break;
      case LAST_MODIFIED_BY_SORT_DESCENDING:
        mapContent(lastModifiedByDescendingSort(debouncedFiles)).then(
          setFileTree,
        );
        break;
      default:
        mapContent(defaultSort(debouncedFiles)).then(setFileTree);
        break;
    }
  };

  const { deselectAll, selectedItems } = useContext(ContentActionContext);

  useTitle(!isSideBarManager && t('content-manager.title'));

  return (
    <>
      <PageHeader>
        {queryPath ? (
          <h2 className="pt-4 text-lg leading-none">
            {t('content-manager.title')}
          </h2>
        ) : (
          <PageTitle>{t('content-manager.title')}</PageTitle>
        )}
        <Link to={URLS.FILE_MANAGER} className="text-xs hover:underline">
          {t('file.manager.use-new-file-manager')}
        </Link>
        <ContentActionBar
          queryPath={queryPath}
          root={root}
          files={debouncedFiles}
        />
      </PageHeader>
      <Content hasPaddingBottom={hasPaddingBottom}>
        <div className="flex flex-grow flex-col">
          <div
            className={c(
              'flex flex-basis-0 flex-grow flex-shrink-0 ',
              !isSideBarManager && 'overflow-hidden',
            )}
          >
            {loading || !fileTree.items ? (
              <div className="flex flex-col flex-grow items-center justify-center">
                <div className="self-center flex-shrink h-5 w-5">
                  <Loader />
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col flex-grow h-full">
                <div
                  className={c(
                    'flex w-full items-center mb-4 space-x-3',
                    isSideBarManager ? 'mt-1' : 'mt-4',
                  )}
                >
                  {isSideBarManager ? (
                    <SideBarBreadCrumbs
                      setQueryPath={setQueryPath}
                      queryPath={queryPath}
                      rootDisplayName={t('content-selector.title.default')}
                    />
                  ) : (
                    <BreadCrumbs
                      root={root}
                      rootDisplayName={t('content-selector.title.default')}
                    />
                  )}
                </div>
                <div className="flex w-full h-full overflow-auto">
                  <ContentTree
                    isHovered={isHovered}
                    setIsDragging={setIsDragging}
                    setQueryPath={setQueryPath}
                    queryPath={queryPath}
                    root={root}
                    fileTree={fileTree}
                    setFileTree={setFileTree}
                    sortTable={sortTable}
                  />
                  {!isSideBarManager && (
                    <ContentPreview
                      close={() => deselectAll()}
                      selectedItems={debouncedFiles.filter(({ id }) =>
                        selectedItems.includes(id),
                      )}
                      className="flex-shrink-0 w-1/4"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Content>
    </>
  );
};

export default memo(ContentManager);
