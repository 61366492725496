import { Link } from 'react-router-dom';

import { PageHeader, PageTitle } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

import * as URLS from '../../utils/constants/urls';

import FileManager from './FileManager';
import FileManagerContextProvider from './FileManagerContextProvider';

const FileManagerContainer = () => {
  const t = useFormatMessage();

  return (
    <FileManagerContextProvider>
      <PageHeader>
        <PageTitle>{t('header.files')}</PageTitle>
        <Link to={URLS.FILES} className="text-xs hover:underline">
          {t('file.manager.use-old-file-manager')}
        </Link>
      </PageHeader>

      <FileManager />
    </FileManagerContextProvider>
  );
};

export default FileManagerContainer;
