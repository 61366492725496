import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaEdit, FaPlus, FaTimes } from 'react-icons/fa';
import { useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { ContextMenu } from '@ftrprf/context-menu';
import {
  Badge,
  CheckBox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Dropdown,
  FilledButton,
  Input,
  InputGroup,
  Label,
  OutlineButton,
  RadioButton,
  VersionLabel,
} from '@ftrprf/tailwind-components';

import { UserContext } from '../../providers/UserProvider';

import useDebounce from '../../hooks/useDebounce';
import useFormatMessage from '../../hooks/useFormatMessage';

import {
  FIND_ALL_LESSON_CONTENT,
  FIND_ENDGOALS_BY_KEY,
  FIND_TAGS_WITH_NAME,
} from '../../api/content';

import CONTENT_TYPES from '../../utils/constants/contentTypes';
import { FILE_EXTENSIONS } from '../../utils/constants/fileTypes';
import LANGUAGES from '../../utils/constants/languages';
import isBetaUser from '../../utils/isBetaUser';

import ContentListSelector from '../ContentListSelector/ContentListSelector';
import FormikUrl from '../Form/FormikUrl';

const ContentMetaDataDialog = ({
  content,
  onDismiss,
  onSubmit,
  error,
  initialType,
  ...props
}) => {
  const isCreating = !content.id;
  const isCreatingLesson = isCreating && initialType === CONTENT_TYPES.LESSON;

  const t = useFormatMessage();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      ...content,
    },
  });

  const [tagsFilter, setTagsFilter] = useState('');
  const [endGoalsFilter, setEndGoalsFilter] = useState('');
  const [tags, setTags] = useState(content.tags);
  const [endGoals, setEndGoals] = useState(content.endGoals);
  const [imageURL, setImageURL] = useState(content.image);
  const [isGroupLesson, setIsGroupLesson] = useState(content.groupLesson);
  const [isExternalShown, setIsExternalShown] = useState(false);
  const [currentContentPath, setCurrentContentPath] = useState('/');
  const [selectedTemplateId, setTemplateId] = useState();

  const debouncedTagsFilter = useDebounce(tagsFilter, 500);
  const debouncedEndGoalsFilter = useDebounce(endGoalsFilter, 500);

  const filterInput = (e) => {
    if (/[^A-Za-z0-9]+/g.test(e.key)) {
      e.preventDefault();
    }
  };
  const templateQueryOptions = {
    variables: {
      page: 0 /*TODO: How many templates will there be?*/,
      filter: [
        { key: 'type', value: CONTENT_TYPES.LESSON_TEMPLATE, operation: ':' },
        { key: 'published', value: true, operation: ':' },
      ],
      sort: {
        isDefault: 'DESC',
        title: 'ASC',
      },
    },
  };
  const {
    loading: templatesLoading,
    error: templatesError,
    data: templatesData,
    //refetch: refetchContentList,
  } = useQuery(FIND_ALL_LESSON_CONTENT, templateQueryOptions);
  const templates = templatesData?.findAllLessonContent.content ?? [];

  const { fetchMore: fetchMoreEndGoals, data: endGoalsData } = useQuery(
    FIND_ENDGOALS_BY_KEY,
    {
      variables: {
        key: endGoalsFilter,
      },
    },
  );

  const { fetchMore: fetchMoreTags, data: tagsData } = useQuery(
    FIND_TAGS_WITH_NAME,
    {
      variables: {
        name: tagsFilter,
      },
    },
  );

  useEffect(() => {
    fetchMoreTags({
      variables: {
        name: tagsFilter,
      },
    });
  }, [tagsData, debouncedTagsFilter, fetchMoreTags, tagsFilter]);

  useEffect(() => {
    fetchMoreEndGoals({
      variables: {
        name: endGoalsFilter,
      },
    });
  }, [endGoalsFilter, debouncedEndGoalsFilter, fetchMoreEndGoals]);

  useEffect(() => {
    if (templatesData?.findAllLessonContent?.content?.length > 0) {
      setTemplateId(templatesData.findAllLessonContent.content[0].id);
    }
  }, [templatesData]);

  const handleTagsInputChange = (value) => {
    setTagsFilter(value);
  };

  const handleEndGoalsInputChange = (value) => {
    setEndGoalsFilter(value);
  };

  const onDismissEnchancer = useCallback(() => {
    onDismiss();
    reset();
  }, [onDismiss, reset]);

  const onSubmitEnhancer = useCallback(
    (data) => {
      if (data.tags[0] instanceof Object) {
        data.tags = data.tags.map((el) => el.name);
      }
      onSubmit({
        ...data,
        endGoals: data.endGoals.map((el) => el.id),
        groupLesson: isGroupLesson,
        groupRoles: data.groupRoles
          ? data.groupRoles.map((role) => {
              if (typeof role === 'string' || role instanceof String) {
                return { role };
              }

              delete role.__typename;

              return role;
            })
          : [],
        type: isCreating ? initialType : data.type,
        image: imageURL,
        templateIdToCopy: selectedTemplateId,
      });
      setTags(data.tags);

      setEndGoals(data.endGoals);
    },
    [
      imageURL,
      initialType,
      isCreating,
      isGroupLesson,
      onSubmit,
      selectedTemplateId,
    ],
  );

  const title = isCreating
    ? t(`content-meta-data-dialog.${initialType}.new_title`)
    : t(`content-meta-data-dialog.${content.type}.update_title`);

  const { user } = useContext(UserContext);

  return (
    <>
      {isExternalShown && (
        <Dialog onDismiss={() => setIsExternalShown(false)}>
          <Formik
            initialValues={{
              url: '',
            }}
            validateOnChange
            validateOnBlur={false}
            validationSchema={object({
              url: string().url('validation.url').required('validation.empty'),
            })}
            onSubmit={({ url }) => {
              setImageURL(url);
              setIsExternalShown(false);
            }}
          >
            {({ isSubmitting, isValid, submitCount }) => (
              <Form>
                <DialogHeader>
                  {t('editor.imageplugin.label-external')}
                </DialogHeader>
                <DialogContent>
                  <FormikUrl name="url" label={t('global.URL')} />
                </DialogContent>
                <DialogActions>
                  <OutlineButton onClick={() => setIsExternalShown(false)}>
                    {t('global.cancel')}
                  </OutlineButton>
                  <FilledButton
                    type="submit"
                    disabled={isSubmitting || (!isValid && submitCount > 0)}
                  >
                    {t('editor.imageplugin.label')}
                  </FilledButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}

      <Dialog onDismiss={onDismissEnchancer} {...props} aria-label={title}>
        <form onSubmit={handleSubmit(onSubmitEnhancer)} className="w-full">
          <DialogHeader>{title}</DialogHeader>
          <DialogContent>
            {/* TITLE */}
            <Label>{t('content.title')}</Label>
            <Controller
              control={control}
              name="title"
              type="text"
              rules={{
                required: {
                  value: true,
                  message: t('validation.required'),
                },
              }}
              defaultValue={content.title}
              render={({ field }) => (
                <Input type="text" defaultValue={content?.title} {...field} />
              )}
            />
            {errors.title && (
              <span className="text-red-500 text-xs">
                {errors.title.message}
              </span>
            )}

            {/* STEAMS/TEMPLATE ONLY */}
            {initialType == CONTENT_TYPES.LESSON_TEMPLATE && (
              <>
                {/* STEAMS/TEMPLATE ONLY: Description */}
                <Label className="mt-3">{t('content.description')}</Label>
                <Controller
                  control={control}
                  name="description"
                  type="text"
                  defaultValue={content.description}
                  render={({ field }) => (
                    <Input
                      type="multiline"
                      defaultValue={content?.description}
                      {...field}
                    />
                  )}
                />

                {/* STEAMS/TEMPLATE ONLY: Summary */}
                <Label className="mt-3">{t('content.summary')}</Label>
                <Controller
                  control={control}
                  name="summary"
                  type="text"
                  defaultValue={content.summary}
                  render={({ field }) => (
                    <Input
                      type="multiline"
                      defaultValue={content?.summary}
                      {...field}
                    />
                  )}
                />
              </>
            )}

            {/* LANGUAGE */}
            <InputGroup className="mt-3">
              <Label className="mt-3">{t('content.language')}</Label>
              <Controller
                control={control}
                name="language"
                type="select"
                rules={{
                  required: {
                    value: true,
                    message: t('validation.required'),
                  },
                }}
                defaultValue={content.language}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Dropdown
                    onBlur={onBlur}
                    onChange={onChange}
                    options={[LANGUAGES.EN, LANGUAGES.NL]}
                    value={value}
                  />
                )}
              />
              {errors.language && (
                <span className="text-red-500 text-xs">
                  {errors.language.message}
                </span>
              )}
            </InputGroup>

            {/* TYPE (exam vs lesson) */}
            {!isCreating && (
              <>
                <Label className="mt-3">{t('content.type')}</Label>
                <Controller
                  control={control}
                  name="type"
                  type="select"
                  rules={{
                    required: {
                      value: true,
                      message: t('validation.required'),
                    },
                  }}
                  defaultValue={content.type}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      onBlur={onBlur}
                      onChange={onChange}
                      options={[CONTENT_TYPES.EXAM, CONTENT_TYPES.LESSON]}
                      value={value}
                    />
                  )}
                />
                {errors.type && (
                  <span className="text-red-500 text-xs">
                    {errors.type.message}
                  </span>
                )}
                <InputGroup className="mt-3">
                  <Label>{t('content.version')}</Label>
                  <VersionLabel className="p-1" version={content.version} />
                </InputGroup>
              </>
            )}

            {/* BETA USER ONLY */}
            {isBetaUser(user) && (
              <>
                {/* BETA USER ONLY: Tags */}
                <Label className="mt-3">{t('content.tags')}</Label>
                <Controller
                  control={control}
                  name="tags"
                  type="select"
                  rules={{
                    required: {
                      value: false,
                      message: t('validation.required'),
                    },
                  }}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <Dropdown
                      isDisabled={!isBetaUser(user)}
                      onBlur={onBlur}
                      onChange={onChange}
                      onKeyDown={filterInput}
                      isMulti={true}
                      options={tagsData?.findTagsWithName?.map((tag) => ({
                        key: tag.id,
                        value: tag.name,
                        label: tag.name,
                      }))}
                      defaultValue={tags?.map((tag) => {
                        if (tag.id) {
                          return {
                            key: tag.id,
                            value: tag.name,
                            label: tag.name,
                          };
                        } else {
                          return {
                            key: tag,
                            value: tag,
                            label: tag,
                          };
                        }
                      })}
                      value={value}
                      onInputChange={handleTagsInputChange}
                      creatable={true}
                    />
                  )}
                />

                {/* BETA USER ONLY: End Goals */}
                <Label className="mt-3">{t('content.endGoals')}</Label>
                <Controller
                  control={control}
                  name="endGoals"
                  type="select"
                  rules={{
                    required: {
                      value: false,
                      message: t('validation.required'),
                    },
                  }}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <Dropdown
                      isDisabled={!isBetaUser(user)}
                      onBlur={onBlur}
                      onChange={onChange}
                      isMulti={true}
                      options={endGoalsData?.findEndGoalsByKey?.map(
                        (endGoal) => ({
                          key: endGoal.id,
                          value: endGoal,
                          label: endGoal.key,
                        }),
                      )}
                      defaultValue={endGoals?.map((endGoal) => {
                        if (endGoal.id) {
                          return {
                            key: endGoal.id,
                            value: endGoal,
                            label: endGoal.key,
                          };
                        } else {
                          return {
                            key: endGoal,
                            value: endGoal,
                            label: endGoal,
                          };
                        }
                      })}
                      value={value}
                      onInputChange={handleEndGoalsInputChange}
                      endgoal={true}
                    />
                  )}
                />
              </>
            )}

            {/* IMAGE */}
            <InputGroup className="mt-3">
              <Label>{t('content.image')}</Label>
              <ContextMenu
                trigger={({ _, toggle }) =>
                  imageURL ? (
                    <div className="relative w-full mt-2">
                      <div className="flex mt-2 absolute top-0 right-0 mr-2 gap-2">
                        <FilledButton onClick={toggle}>
                          <FaEdit />
                        </FilledButton>
                        <FilledButton error onClick={() => setImageURL(null)}>
                          <FaTimes />
                        </FilledButton>
                      </div>
                      <img
                        className="w-full"
                        src={imageURL}
                        alt={t('content-card.image_alt')}
                      />
                    </div>
                  ) : (
                    <FilledButton iconBefore={FaPlus} onClick={toggle}>
                      {t('content-selector.image')}
                    </FilledButton>
                  )
                }
              >
                {({ close }) => (
                  <div className="w-64 h-64 bg-white border border-gray">
                    <ContentListSelector
                      onFileClick={(url) => {
                        setImageURL(url);
                        close();
                      }}
                      onExternalClick={() => {
                        setIsExternalShown(true);
                        close();
                      }}
                      allowedTypes={FILE_EXTENSIONS.IMAGE.extensions}
                      placeholder={t('content-selector.url')}
                      currentPath={currentContentPath}
                      setCurrentPath={setCurrentContentPath}
                    />
                  </div>
                )}
              </ContextMenu>
            </InputGroup>
            {isCreatingLesson && templates.length > 0 && (
              <InputGroup className="">
                <Label className="font-semibold">{t('content.template')}</Label>
                {!templatesLoading &&
                  !templatesError &&
                  templates.map((template) => (
                    <label key={template.id} className="flex flex-row ">
                      <RadioButton
                        name={`radio_${template.id}`}
                        checked={selectedTemplateId === template.id}
                        label={template.title}
                        value={template.id}
                        onChange={(e) => {
                          setTemplateId(e.target.value);
                        }}
                      />
                      <div className="my-1">
                        <div className="flex items-center">
                          <span className="mr-3 mb-0">{template.title}</span>

                          {template.isDefault && (
                            <Badge success className="py-px text-xs px-2">
                              Default
                            </Badge>
                          )}
                        </div>
                        {template.summary && (
                          <div className="text-xs text-gray-600 ">
                            {template.summary}
                          </div>
                        )}
                      </div>
                    </label>
                  ))}
              </InputGroup>
            )}

            {/* GROUP ROLES */}
            <>
              <InputGroup className="">
                <CheckBox
                  label={t('content.group_lesson')}
                  checked={
                    templates[
                      templates.findIndex(
                        (obj) => obj.id === selectedTemplateId,
                      )
                    ]?.groupLesson || isGroupLesson
                  }
                  disabled={
                    !templates[
                      templates.findIndex(
                        (obj) => obj.id === selectedTemplateId,
                      )
                    ]?.isDefault
                  }
                  onChange={(e) => setIsGroupLesson(e)}
                />
              </InputGroup>

              {isGroupLesson && (
                <>
                  <Label className="mt-3">{t('content.groupRoles')}</Label>
                  <Controller
                    control={control}
                    name="groupRoles"
                    type="select"
                    rules={{
                      required: {
                        value: false,
                        message: t('validation.required'),
                      },
                    }}
                    render={({ field: { onBlur, onChange, value } }) => (
                      <Dropdown
                        onBlur={onBlur}
                        onChange={onChange}
                        onKeyDown={filterInput}
                        isMulti={true}
                        options={[]}
                        defaultValue={
                          value
                            ? value.map((role) => ({
                                key: role.id,
                                value: role,
                                label: role.role,
                              }))
                            : []
                        }
                        value={value}
                        creatable={true}
                      />
                    )}
                  />{' '}
                </>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <OutlineButton onClick={onDismissEnchancer}>
              {t('global.cancel')}
            </OutlineButton>
            <FilledButton
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              {isCreating ? t('global.add') : t('global.save')}
            </FilledButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

ContentMetaDataDialog.defaultProps = {
  content: {
    title: '',
    language: LANGUAGES.NL,
    image: '',
    clan: null,
    level: null,
    programId: undefined,
    tags: [],
    endGoals: [],
    groupLesson: false,
  },
};

export default ContentMetaDataDialog;
