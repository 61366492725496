import c from '../../utils/c';
import getButtonStyle from '../../utils/getComponentStyle';

import { Loader } from '../Loader';

const buttonStyles = {
  ACCENT: 'text-center bg-accent-100 text-accent-600',
  SECONDARY: 'text-center bg-gray-200',
  TERTIARY: 'text-center bg-gray-300',
  SUCCESS: 'text-center bg-green-100 text-green-700',
  ERROR: 'text-center bg-red-100 text-red-700',
  SEND: 'text-center text-white bg-indigo-600',
};

export const FilledButtonLink = ({
  children,
  className,
  accent,
  success,
  secondary,
  tertiary,
  error,
  send,
  small,
  iconBefore,
  iconBeforeSize,
  iconAfter,
  iconSizeClassName,
  loading,
  loaderClassName,
  badge,
  ...props
}) => {
  const style = getButtonStyle(
    buttonStyles,
    { accent, success, secondary, tertiary, error, send },
    'ACCENT',
  );

  const size = small ? 'px-2 py-1 text-xs' : 'px-4 py-1';

  const IconBefore = iconBefore;
  const IconAfter = iconAfter;

  return (
    <a
      className={c(
        className,
        buttonStyles[style],
        size,
        'hover:shadow-none hover:translate-x-px hover:translate-y-px',
        'transform rounded hover:outline hover:outline-2 hover:outline-blue-500 focus:outline focus:outline-blue-500 focus:outline-2',
        'flex items-center box-border',
      )}
      {...props}
    >
      {iconBefore && (
        <span className="mr-2">
          {loading ? (
            <div className={loaderClassName}>
              <Loader />
            </div>
          ) : (
            <IconBefore
              className={iconSizeClassName}
              width={iconBeforeSize}
              height={iconBeforeSize}
            />
          )}
        </span>
      )}
      {loading && !iconBefore && !iconAfter ? (
        <div className={loaderClassName}>
          <Loader />
        </div>
      ) : (
        children
      )}
      {iconAfter && (
        <span className="ml-2">
          {loading ? (
            <div className={loaderClassName}>
              <Loader />
            </div>
          ) : (
            <IconAfter className={iconSizeClassName} />
          )}{' '}
        </span>
      )}
      {badge && (
        <div className="absolute top-0 right-0 text-xxs py-1 px-2 -mr-1 bg-accent-100 text-accent-600 rounded-full leading-none -mt-3 font-semibold shadow-sm">
          {badge}
        </div>
      )}
    </a>
  );
};
