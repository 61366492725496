export const ROOT = '/';
export const MANUAL_LOGIN = '/manual-login';
export const CALLBACK = '/callback';
export const LOGOUT = '/logout';
export const CONTENT = '/content';
export const EXAM = '/exam';
export const LESSON = '/lesson';
export const LESSONS = '/lessons';
export const LESSON_TEMPLATES = '/templates';
export const LESSON_TEMPLATE = '/template';
export const EXAMS = '/exams';
export const FILES = '/files';
export const FILE_MANAGER = '/file-manager';
export const ADMIN = '/admin';
export const EXERCISES = '/exercises';
export const EXERCISE = '/exercise';
