import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { INITIALIZE_CONTENT_NAVIGATION_TREE } from '../../../../api/filemanager';

const useContentNavigationTree = (
  enabled = true,
  startFolderId = null,
  levels = 3,
  onlyFolders = true,
) => {
  const [fetch, { data, loading, error }] = useLazyQuery(
    INITIALIZE_CONTENT_NAVIGATION_TREE,
    {
      fetchPolicy: 'network-only',
    },
  );
  const parsedData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return JSON.parse(data.initializeContentNavigationTree.content);
  }, [data]);

  useEffect(() => {
    if (enabled) {
      fetch({
        variables: {
          startFolderId,
          levels,
          onlyFolders,
        },
      });
    }
  }, [enabled, fetch, levels, onlyFolders, startFolderId]);

  return { data: parsedData, loading, error, fetch };
};

export default useContentNavigationTree;
