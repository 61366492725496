import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import { UPLOAD_FILES } from '../../../../api/filemanager';

const useFileUpload = () => {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);

  const [uploadFiles, { loading, error }] = useMutation(UPLOAD_FILES, {
    onCompleted: () => {
      addNotification({
        type: 'success',
        content: t('file.manager.files-uploaded'),
      });
    },
  });

  return { uploadFiles, loading, error };
};

export default useFileUpload;
