import { useContext } from 'react';

import useContentNavigationTree from '../../../hooks/graphql/file-manager/queries/useContentNavigationTree';
import useContentNavigationTreeFromPath from '../../../hooks/graphql/file-manager/queries/useContentNavigationTreeFromPath';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { FileManagerContext } from '../FileManagerContextProvider';

import TreeFolderCollapsable from './TreeFolderCollapsable';

const FileManagerTree = () => {
  const t = useFormatMessage();
  const { activePath } = useContext(FileManagerContext);

  const { data: rootFolders } = useContentNavigationTree(activePath === '/');
  const { data: foldersFromPath } = useContentNavigationTreeFromPath(
    activePath,
    activePath?.length > 2,
  );

  return (
    <div className="flex-auto w-1/5 overflow-auto">
      <h2 className="sr-only">{t('file.manager.tree.title')}</h2>
      <TreeFolderCollapsable
        folder={{
          id: null,
          path: '/',
          name: t('header.files'),
          children: foldersFromPath || rootFolders,
        }}
        isOpen={true}
      />
    </div>
  );
};

export default FileManagerTree;
