import {
  FaArchive,
  FaArrowLeft,
  FaCog,
  FaCopy,
  FaDesktop,
  FaEdit,
  FaEye,
  FaHome,
  FaPaperPlane,
  FaTrash,
  FaUsers,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import {
  DropdownButton,
  DropdownMenu,
  Editable,
  FilledButton,
  MenuButton,
} from '@ftrprf/tailwind-components';

import RelativeDate from '../../../components/RelativeDate';

import useFormatDate from '../../../hooks/useFormatDate';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { CONTENT } from '../../../utils/constants/urls';
import { lastSavedAtAtom } from '../utils/atom';

const ContentEditorHeader = ({
  content,
  currentSlideId,
  overviewRoute,
  disabled,
  hasViewModeSelector,
  onSaveTitle,
  setShowMetaDataDialog,
  setShowCreateNewVersionDialog,
  setShowPublishConfirmDialog,
  setShowCopyConfirmDialog,
  setShowArchiveConfirmDialog,
  setShowRemoveConfirmDialog,
}) => {
  const t = useFormatMessage();
  const formatDate = useFormatDate();

  const [lastSavedAt] = useRecoilState(lastSavedAtAtom);

  return (
    <div className="w-full h-16 py-2 mx-auto flex items-center justify-between relative px-4 text-sm border-b border-gray-300">
      <div>
        <Link to={overviewRoute}>
          <FilledButton secondary iconBefore={FaArrowLeft}>
            <span className="hidden xl:inline xl:mr-2">
              {t('global.go_back')}
            </span>
          </FilledButton>
        </Link>
      </div>
      <div className="flex">
        <Editable
          contentEditable={!disabled}
          className="text-lg font-semibold relative p-1 rounded"
          singleLine
          maxChars={99}
          onBlur={onSaveTitle}
        >
          {content.title}
        </Editable>
      </div>
      <div className="flex-grow-0 flex self-end items-center h-full">
        <span
          style={{ minWidth: '4rem' }}
          className="text-gray-700 text-sm"
          title={formatDate(lastSavedAt)}
        >
          {t('content-editor.header.last_edit_was')}{' '}
          <RelativeDate date={lastSavedAt} />
        </span>
        <DropdownMenu
          trigger={({ isOpen }) => (
            <DropdownButton
              iconBefore={FaCog}
              className="font-semibold"
              isOpen={isOpen}
              disabled={content.archived}
            >
              {t('content-editor.header.settings')}
            </DropdownButton>
          )}
        >
          <MenuButton
            iconBefore={FaEdit}
            onClick={() => setShowMetaDataDialog(true)}
            disabled={content.published || content.archived}
          >
            {t('content-editor.header.metadata')}
          </MenuButton>
          <MenuButton
            iconBefore={FaCopy}
            onClick={() => setShowCopyConfirmDialog(true)}
            disabled={content.archived}
          >
            {t('content-editor.header.copy')}
          </MenuButton>
          <MenuButton
            iconBefore={FaArchive}
            onClick={() => setShowArchiveConfirmDialog(true)}
            disabled={content.archived}
          >
            {t('content-editor.header.archive')}
          </MenuButton>
          <MenuButton
            iconBefore={FaTrash}
            onClick={() => setShowRemoveConfirmDialog(true)}
            disabled={content.archived}
          >
            {t('global.delete')}
          </MenuButton>
        </DropdownMenu>

        {hasViewModeSelector ? (
          <DropdownMenu
            trigger={({ isOpen }) => (
              <DropdownButton
                iconBefore={FaEye}
                className="font-semibold"
                isOpen={isOpen}
              >
                {t('content-editor.header.preview')}
              </DropdownButton>
            )}
          >
            <Link
              to={`${CONTENT}/${content.id}/classical/${currentSlideId}`}
              target="_blank"
            >
              <MenuButton iconBefore={FaUsers}>
                {t('view-modes.classical')}
              </MenuButton>
            </Link>
            <Link
              to={`${CONTENT}/${content.id}/selfstudy/${currentSlideId}`}
              target="_blank"
            >
              <MenuButton iconBefore={FaHome}>
                {t('view-modes.selfstudy')}
              </MenuButton>
            </Link>
            <Link
              to={`${CONTENT}/${content.id}/projection/${currentSlideId}`}
              target="_blank"
            >
              <MenuButton iconBefore={FaDesktop}>
                {t('view-modes.projection')}
              </MenuButton>
            </Link>
          </DropdownMenu>
        ) : (
          <Link
            to={`${CONTENT}/${content.id}/classical/${currentSlideId}`}
            target="_blank"
          >
            <FilledButton iconBefore={FaEye}>
              {t('content-editor.header.preview')}
            </FilledButton>
          </Link>
        )}

        <div className="ml-4">
          {content.published ? (
            <FilledButton
              success
              onClick={() => setShowCreateNewVersionDialog(true)}
              disabled={content.archived}
            >
              {t('content-editor.header.new_version')}
            </FilledButton>
          ) : (
            <FilledButton
              onClick={() => setShowPublishConfirmDialog(true)}
              iconBefore={FaPaperPlane}
              disabled={content.archived}
              success
            >
              {t('content-editor.header.publish')}
            </FilledButton>
          )}
        </div>
      </div>
    </div>
  );
};

ContentEditorHeader.defaultProps = {
  hasViewModeSelector: true,
};

export default ContentEditorHeader;
